/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />

@keyframes fadeInOnLoad {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes message-in {
  to {
    top: 250px;
    opacity: 1;
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes scale {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide {
  0% {
    left: 0;
  }

  50% {
    left: 100px;
  }

  100% {
    left: 0;
  }
}
@-moz-keyframes slide {
  0% {
    left: 0;
  }

  50% {
    left: 100px;
  }

  100% {
    left: 0;
  }
}
@keyframes slide {
  0% {
    left: 0;
  }

  50% {
    left: 100px;
  }

  100% {
    left: 0;
  }
}
