/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />

// Fade images in after loading
.lazy {
  opacity: 0;
  transition: all 800ms;
  &.lazy--hidden {
    opacity: 0;
  }
  &.lazy--loaded {
    opacity: 1;
  }
}
// Font sizes
.font-size-14 {
    @include font-size(1.4);
}
.font-size-24 {
  @include font-size(2.4);
}

// Typography
.font-italics {
    font-style: $italics;
}
.whitney-book {
  font-family: $font-main-book;
}
.text--lowercase {
    text-transform: none;
}

.ul--show-discs {
    list-style: disc;
    margin: 0 20px;
    padding: 0 20px;

    > li {
        margin-bottom: 10px;
    }
}

.line-height-one-and-half {
    line-height: 1.5em;
}

// Alignment including flexbox
.text--center {
  text-align: center;
}
.inline-block {
    display: inline-block;
}
.align-vertically {
  @include align-items(center);
}

i.arrow {
  display: inline-block;
  transition: all 500ms;
  span {
    border: solid $red;
    border-width: 0 2px 2px 0;
    background: transparent;
    cursor: pointer;
    display: block;
    height: calc(100% / 2);
    margin-left: 5px;
    padding: 3px;
  }
  .arrow--right {
    @include rotate-caret(right);
  }

  .arrow--left {
    @include rotate-caret(left);
  }

  .arrow--up {
    @include rotate-caret(up);
  }

  .arrow--down {
    @include rotate-caret(down);
  }
}

.qtr-report-pdf-icon {
    position: relative;
    top: 8px;
}

// Margins
.mt-20 {
  margin-top: 20px;
}

.mt-80 {
    margin-top: 80px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

// Colors
.color-red {
    color: $red;
}

// Visibility
.mobile--is-visible {
  display: none;

  @include bp-max-420 {
    display: block;
  }
}

.primary {
  .mobile--is-visible {
    @include bp-max-1024 {
      display: block;
    }
  }
}

.mobile--not-visible {
  display: block;

  @include bp-max-420 {
    display: none;
  }
}
.hidden {
  display: none;
}

img.ap-gray-border {
    border: 20px solid $lightGray;

    @include bp-max-420 {
        max-width: 100%;
    }
}

.art-program__goldbrush {
    display: block;
    margin: 20px auto;
    max-width: 100%;
}

// Trash me before prod
#hp-section-2,
#hp-section-3,
.anim-perspective-wrap {
  perspective: 220px;
}
#anim-perspective,
.anim-perspective {
  transition: transform 500ms;
}
