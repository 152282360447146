/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
.sticky {
    .tabbed-content {
        &.tabbed-content--no-hero {
            ul.tabbed-content__tabs {
                top: 91px;

                @include bp-max-768 {
                    top: 84px;
                }

                @include bp-max-420 {
                    top: 80px;
                }
            }
        }
    }
}

.tabbed-content {
    min-height: 1000px;
    position: relative;
    transition: min-height 500ms;

    @include bp-min-1920 {
        min-height: 1040px;
    }

    @include bp-max-1024 {
        min-height: 720px;
    }

    @include bp-max-768 {
        min-height: 1070px;
    }

    @include bp-max-420 {
        min-height: 1094px;
    }

    &.tabbed-content--not-absolute {
        min-height: inherit;

        > h2.text--center {
            margin-top: 40px;

            & + .tabbed-content__content {
                margin-top: 0;

                .not-full-width {
                    margin-top: 30px;
                }
            }
        }

        ul.tabbed-content__tabs--sticky + h2.text--center {
            margin-top: 120px;

            @include bp-max-420 {
                margin-top: 40px;
            }
        }
    }

    .col2-eq--text-next-to-image + & {
        margin-top: 100px;
    }

    &.tabbed-content--dynamic-height {
        & + .has-gray-bg {
            &.full-width {
                margin-top: 80px;
            }
        }
    }

    &:not(.tabbed-content--dynamic-height) {
        ul.tabbed-content__tabs--sticky {
            & + .tabbed-content__content {
                margin-top: 180px;

                @include bp-max-1024 {
                    margin-top: 90px;
                }
            }


            & + #scroll-to-our-story {
                margin-top: 80px;

                @include bp-max-1024 {
                    margin-top: 60px;
                }
            }

            & + #scroll-to-our-presence {
                margin-top: 80px;

                @include bp-max-1024 {
                    margin-top: 100px;
                }

                @include bp-max-768 {
                    margin-top: 100px;
                }

                @include bp-max-420 {
                    margin-top: 80px;
                }
            }

            & + .not-full-width {
                margin-top: 80px;
            }
        }
    }

    &.tabbed-content--no-hero {
        ul.tabbed-content__tabs {
            margin: 0;
            position: fixed;
            top: 144px;
            width: 100%;
            transition: top 100ms linear;
        }

        .tabbed-content__content {
            margin-top: 150px;

            .tabbed-content--no-hero__header {
                margin: 0px 75px;

                @include bp-max-420 {
                    margin: 0px 30px;
                }
            }
        }
    }

    ul.tabbed-content__tabs {
        background-color: $lightGray;
        @include flexbox;
        @include align-content(center);
        list-style: none;
        margin: 0 60px;
        overflow: hidden;
        padding: 0;
        position: relative;
        top: -40px;
        // position: sticky;
        // top: 80px;
        // margin-top: -40px;
        transition: top 400ms linear;
        z-index: 2;

        @include bp-max-1024 {
            margin: 0;
            overflow-x: scroll;
            top: 0;
            touch-action: pan-x;
        }

        @include bp-max-768 {
            top: -10px;
        }

        &.has-photo-below {
            top: 40px;

            @include bp-max-420 {
                top: 0;
            }
        }

        &.has-parallax {
            top: 40px;

            @include bp-max-1024 {
                top: 0;
            }
        }

        &.tabbed-content__tabs--sticky {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
            margin: 0;
            position: fixed;
            top: 90px;
            width: 100%;
            z-index: 2;

            @include bp-min-1920 {
                max-width: 1920px;
            }

            @include bp-max-1024 {
                top: 90px;
            }

            @include bp-max-768 {
                top: 80px;
            }

            .tabbed-content__tab--mobile-scroll-indicator {
                opacity: 1;
                position: fixed;
                z-index: 1;
            }
        }

        @include bp-min-1920 {
            margin-left: 150px;
            margin-right: 150px;
        }

        & + .tabbed-content--full-width {
            margin-top: 0;
        }

        > .tabbed-content__tab,
        li {
            border-right: 1px solid $white;
            cursor: pointer;
            @include flexbox;
            @include align-items(center);
            @include flex(1 1 auto);
            font-family: $font-main-medium;
            padding: 30px 25px;
            text-align: center;

            @include bp-min-1920 {
                padding: 25px 40px;
            }

            @include bp-max-1024 {
                @include flex(1 0 350px);
                height: 58px;
                padding: 10px 25px;
            }

            @include bp-max-420 {
                @include font-size(1.6);
                @include flex(1 0 290px);
            }

            &.tabbed-content__content--financial-solutions {
                @include flex(1 1 300px);

                @include bp-min-1920 {
                    @include flex(1 1 400px);
                }

                @include bp-max-1024 {
                    @include flex(1 0 350px);
                }
            }

            &:first-of-type {
                background-color: $red;
                border-right: none;
                color: $white;
                cursor: default;
                @include flex(0 1 auto);
                font-family: $font-main-semibold;
                position: relative;
                text-transform: uppercase;
                white-space: nowrap;

                &:after {
                    border-right: 20px solid transparent;
                    border-top: 160px solid transparent;
                    border-left: 20px solid $red;
                    content: "";
                    position: absolute;
                    top: 0;
                    right: -40px;
                }
            }

            &:last-of-type {
                border-right: none;
            }

            > span {
                margin: auto;
            }

            &.tabbed-content__tab--active {
                color: $red;
                cursor: default;

                a {
                    color: $red !important;
                }

                .fill {
                    width: 100%;
                }
            }

            &.tabbed-content__tab--link {
                padding: 0;


                > a {
                    color: $darkGray;
                    display: block;
                    height: 100%;
                    @include flexbox;
                    @include justify-content(center);
                    @include align-items(center);
                    margin: auto;
                    width: 100%;

                    @include bp-max-1024 {
                        padding: 20px 0px;
                    }
                }
            }
        }

        .tabbed-content__tab,
        .tabbed-content__scroll-to,
        .tabbed-content__tab--link {
            overflow: hidden;
            position: relative;

            .fill {
                background-color: darken($lightGray, 10%);
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                z-index: -1;
                transition: all 400ms cubic-bezier(0.13, 0.74, 0.34, 1);
            }

            &:hover,
            &.tabbed-content__tab--link--active {
                color: $red;

                a {
                    color: $red;
                }

                .fill {
                    width: 100%;
                }
            }
        }

        & + .not-full-width {
            margin: 0px 60px;

            @include bp-max-1024 {
                margin: 40px 20px;
            }
        }

        .tabbed-content__tab--mobile-scroll-indicator {
            background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(158,158,158,1) 100%);
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(158,158,158,1) 100%);
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(158,158,158,1) 100%);
            display: none;
            height: 58px;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 80px;
            width: 36px;
            z-index: -1;
            -webkit-transition: all 400ms 300ms linear;
            transition: all 400ms 300ms linear;

            @include bp-max-1024 {
                display: block;
                top: 90px;
            }

            @include bp-max-768 {
                top: 80px;
            }
        }
    }

    #scroll-to-our-clients {
        margin-top: 0;
        padding: 80px 0px;

        .not-full-width {
            margin-top: 0;
        }
    }

    #scroll-to-area-of-expertise {
        margin-top: 0;
        padding-top: 80px;

        @include bp-max-420 {
            padding-top: 20px;
        }

        .not-full-width {
            margin-top: 0;
        }
    }

    .tabbed-content__content {
        margin-top: 40px;
        width: 100%;

        @include bp-max-1024 {
            margin-top: 0;
        }

        @include bp-max-768 {
            margin-top: 20px;
        }

        .not-full-width {
            @include bp-max-420 {
                padding: 0;
            }

            &.not-full-width--has-tabs {
                @include bp-max-420 {
                    margin: 0;
                }

                .on-page-tabs__tabs {
                    @include bp-max-420 {
                        margin: 0;
                    }
                }

                .on-page-tabs__contents {
                    @include bp-max-420 {
                        padding: 0px 20px;
                    }
                }
            }
        }

        &.tabbed-content--has-fade-in-out {
            opacity: 0;
            position: absolute;
            top: -10px;
            left: 0;
            transition: all 500ms;

            .not-full-width:first-child {
                @include bp-max-1024 {
                    margin: 80px 30px 0px;
                }
            }
        }

        &.tabbed-content__content--active {
            opacity: 1;
            top: 0;
            z-index: 1;
        }

        &.tabbed-content--full-width {
            .full-width.has-gray-bg {
                h2 {
                    @include bp-max-420 {
                        padding: 0px 15px;
                    }
                }
            }
        }

        .col2-eq--rows-wrapper {
            margin-top: 20px;

            @include bp-max-1024 {
                margin-top: 40px;
            }

            @include bp-max-420 {
                margin-top: 20px;
            }

            > h2 {
                margin-left: 150px;

                @include bp-max-1024 {
                    margin-left: 30px;
                }

                @include bp-max-420 {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }

            .col2-eq {
                margin-top: 0;

                @include bp-max-1024 {
                    padding: 0px 30px;
                }

                @include bp-max-420 {
                    padding-left: 0;
                    padding-right: 0;
                }

                .col2-eq__l {
                    @include bp-max-1024 {
                        @include flex(1 1 50%);
                        padding-left: 0;
                        width: 50%;
                    }

                    @include bp-max-420 {
                        @include flex(1 1 100%);
                        margin-top: 40px;
                        padding: 0px 30px;
                    }
                }

                .col2-eq__r {
                    @include bp-max-1024 {
                        @include flex(1 1 50%);
                        margin-right: 0;
                        margin-left: 0;
                        padding-right: 0;
                    }

                    @include bp-max-420 {
                        @include flex(1 1 100%);
                        margin-top: 40px;
                        padding: 0px 30px;
                    }
                }

                &.our-presence {
                    margin-bottom: 20px;

                    &:last-of-type {
                        margin-bottom: 80px;
                    }
                }
            }

            .our-presence--one-col {
                margin: 20px 0px 0px 150px;

                @include bp-max-1024 {
                    margin: 20px 30px 0px 30px;
                }

                @include bp-max-420 {
                    margin: 40px 30px 0px 30px;
                }
            }

            
        }

        h2 + .not-full-width {
            margin: 40px 150px 0px;
        }

        .not-full-width {
            margin: 100px 150px 0px;

            &.not-full-width--has-row-of-cards {
                @include bp-max-1024 {
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            @include bp-max-1024 {
                margin: 30px 30px 0px;
            }

            p + .btn--big {
                @include bp-max-420 {
                    margin-top: 30px;
                }
            }
        }

        .tabbed-content__full-width-photo-with-overlay-text {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            @include align-content(center);
            @include flex-wrap(wrap);
            transition: all 400ms linear;

            > h1:first-of-type {
                @include font-size(4.8);
                color: $white;
                @include flex(1 1 100%);
                font-family: $font-main-medium;
                letter-spacing: 0;
                margin-bottom: 20px;
                text-align: center;
                text-transform: none;

                @include bp-max-1024 {
                    @include font-size(3.6);
                }

                @include bp-max-768 {
                    @include font-size(2.7);
                    margin-bottom: 40px;
                }

                @include bp-max-420 {
                    margin-bottom: 20px;
                }
            }

            > p {
                color: $white;
                @include flex(1 1 100%);
                @include font-size(2.4);
                padding: 0 30%;
                text-align: center;

                @include bp-max-1024 {
                    @include font-size(1.8);
                    padding: 0 30px;
                }

                @include bp-max-420 {
                    @include font-size(1.6);
                    padding: 0 30px;
                }
            }

            &.tabbed-content__our-story {
                @include backImage( "../img/about-us-our-story-tab-hero.jpg", no-repeat, left top, cover, 801px, 0 );

                @include bp-max-1024 {
                    height: 575px;
                }

                @include bp-max-768 {
                    height: 360px;
                }

                @include bp-max-420 {
                    height: 250px;
                }
            }
        }

        .full-width.has-gray-bg {
            padding: 60px 150px;

            @include bp-max-768 {
                padding: 60px 30px;
            }

            @include bp-max-420 {
                padding: 0px 0px 30px;
            }

            hr {
                border: 1px solid $gray;
                margin: 40px 0px;

                @include bp-max-420 {
                    margin: 20px 0px;
                }
            }

            > div {
                @include bp-max-420 {
                    padding: 0px 15px;
                }
            }

            .tabbed-content__timeline {
                padding-top: 0;

                @include bp-max-420 {
                    padding: 20px 15px 0px;
                }

                > p {
                    @include bp-max-420 {
                        padding: 0px 15px;
                    }
                }

                .timeline__row {
                    @include flexbox;
                    @include align-items(center);

                    @include bp-max-420 {
                        margin-bottom: 20px;
                    }

                    .timeline__year {
                        @include font-size(3.6);
                        min-width: 100px;

                        @include bp-max-420 {
                            @include font-size(2.4);
                            min-width: 55px;
                        }
                    }

                    .timeline__dot {
                        min-height: 70px;
                        padding: 0 40px;
                        position: relative;
                        width: 20px;

                        @include bp-max-420 {
                            min-width: 40px;
                            padding: 0;
                        }

                        &:before {
                            background-color: $red;
                            box-shadow: 3px 6px 3px rgba(0, 0, 0, 0.2);
                            border-radius: 100%;
                            content: "";
                            height: 20px;
                            position: absolute;
                            top: 25px;
                            left: 20px;
                            width: 20px;
                            z-index: 1;
                            transition: all 400ms;

                            @include bp-max-420 {
                                left: 10px;
                            }
                        }

                        &:after {
                            background-color: $gray;
                            content: "";
                            height: 100%;
                            position: absolute;
                            top: 45px;
                            left: 29px;
                            width: 2px;
                            transition: all 400ms;

                            @include bp-max-420 {
                                left: 19px;
                            }
                        }
                    }

                    .timeline__dot:first-of-type {
                        &:before {
                            height: 0px;
                        }
                    }

                    .timeline__event {
                        @include font-size(2);

                        @include bp-max-420 {
                            @include font-size(1.6);
                        }
                    }

                    &:last-of-type {
                        .timeline__dot {
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }

            .tabbed-content__annual-reports {
                @include bp-max-420 {
                    padding: 0px 15px;
                }
            }

            .btn.btn-solid.btn--big {
                @include bp-max-768 {
                    margin-top: 50px;
                }
            }
        }

        .has-quad__quad--right + .has-quad__quad--left {
            @include bp-max-768 {
                margin-bottom: 0;
            }
        }

        .col2-eq--fw {

            &.col2-eq {
                @include bp-max-768 {
                    @include flex-wrap(wrap);
                }

                &.col2-eq__r--has-image {
                    .col2-eq__l {
                        @include bp-max-768 {
                            @include order(2);
                        }
                    }

                    .col2-eq__r {
                        @include bp-max-768 {
                            @include order(1);
                        }
                    }
                }
            }

            .col2-eq__l {
                padding-left: 100px;

                @include bp-max-1024 {
                    padding-left: 0;
                    margin-left: 0;
                }

                &.has-quad__quad {
                    padding-left: 0;
                    margin-left: 0;
                }

                .has-quad__copy {
                    @include bp-max-420 {
                        margin-left: 0;
                        margin-right: 0;
                        padding: 0 30px;
                    }
                }
            }

            .col2-eq__r {
                margin-right: 50px;
                padding-right: 100px;

                @include bp-max-1024 {
                    padding-right: 0;
                }

                @include bp-max-768 {
                    margin-left: 30px;
                    padding-right: 0;
                    width: 100%;
                }

                @include bp-max-420 {
                    margin: 0;
                    margin-top: 20px;
                    padding: 0px 30px;
                }

                &.has-quad__quad {
                    margin-right: 0;
                    padding-right: 0;

                    @include bp-max-768 {
                        -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        padding-left: 0;
                    }
                }

                .has-quad__copy {
                    @include bp-max-420 {
                        padding: 0 30px;
                    }
                }
            }
        }
    }

    h1.mobile--is-visible {
        text-align: center;
        width: 92%;
    }

    .col2-eq {
        width: auto;
        width: initial;

        &.not-full-width {
            margin: 100px 0px;

            @include bp-max-420 {
                margin: 30px 0px;
            }

            .col2-eq__l {
                &.has-quad__quad {
                    margin-left: 150px;

                    @include bp-max-1024 {
                        // margin-left: 0;
                        margin-left: 75px;
                        padding-right: 40px;
                        width: 50%;
                    }

                    @include bp-max-420 {
                        margin-left: 0;
                        padding-right: 0;
                        width: 100%;
                    }
                }
            }

            .col2-eq__r {
                margin-right: 150px;

                @include bp-max-1024 {
                    // margin-right: 15px;
                    margin-right: 75px;
                }

                @include bp-max-1024 {
                    margin-right: 15px;
                }

                .has-quad__copy {
                    @include bp-max-768 {
                        padding-right: 20px;
                    }

                    @include bp-max-420 {
                        padding-right: 0;
                    }
                }
            }
        }

        &.has-gray-bar {
            margin-left: 100px;
            margin-right: 100px;
            margin-bottom: 100px;

            @include bp-min-1920 {
                margin-left: 250px;
                margin-right: 250px;
            }

            @include bp-max-1024 {
                margin: 100px 0px 25px;
                padding: 0px 50px 40px;
            }

            @include bp-max-768 {
                // margin-left: 20px;
                // margin-right: 20px;
                // margin-bottom: 25px;
                margin: 75px 0px 50px;
            }

            @include bp-max-420 {
                margin-left: 0;
                margin-right: 0;
                padding: 0px 0px 40px;
                margin-top: 40px;
            }

            &.has-quad__quad--scroller,
            &.has-quad__quad--scroller.col2-eq--fw.has-quad.has-quad__quad--right {
                .col2-eq__r {
                    @include bp-max-768 {
                        width: 40%;
                    }

                    @include bp-max-420 {
                        width: 100%;
                        right: 0;
                    }
                }
            }

            &.col2-eq--fw {
                margin-right: 0;
                margin-left: 0;

                .col2-eq__r.has-quad__quad {
                    margin-right: 150px;
                }

                .col2-eq__l {
                    margin-left: 150px;
                    padding-left: 0;
                }
            }
        }

        &.fly-in {
            overflow: hidden;
        }

        &.has-quad__quad--scroller.has-quad__quad--scroller-fw {
            .col2-eq__l {
                @include bp-max-1024 {
                    margin-left: 0;
                    padding-right: 0;
                    padding-left: 0;
                    width: 100%;
                }

                .has-quad__copy {
                    margin-bottom: 80px;

                    @include bp-max-1024 {
                        margin-bottom: 60px;
                    }

                    @include bp-max-768 {
                        margin-bottom: 85px;
                    }

                    @include bp-max-420 {
                        margin-bottom: 0;
                    }
                }

                .has-quad__copy + .has-quad__carousel {
                    margin: 60px 0px 10px;
                    margin-top: auto;

                    @include bp-max-420 {
                        margin-top: 60px;
                        padding: 0;
                    }
                }
            }
        }

        &.col2-eq--fw.has-quad {
            @include bp-max-768 {
                @include flex-wrap(wrap);
            }

            .col2-eq__l,
            .col2-eq__r {
                @include bp-max-768 {
                    padding: 0px 30px;
                }

                @include bp-max-420 {
                    padding: 0;
                }
            }

            .col2-eq__r.has-quad__quad,
            .col2-eq__l.has-quad__quad {
                @include bp-max-768 {
                    padding: 0;
                    width: 100%;
                }
            }

            &.has-quad__quad--left {
                .col2-eq__l {
                    @include bp-max-1024 {
                        width: 50%;
                    }

                    @include bp-max-768 {
                        @include order(2);
                        width: 100%;
                    }

                    @include bp-max-420 {
                        @include order(1);
                    }
                }

                .col2-eq__r {
                    @include bp-max-1024 {
                        padding: 0px 30px;
                        width: 50%;
                    }

                    @include bp-max-768 {
                        @include order(1);
                        margin: 0;
                        padding: 0px 30px;
                        width: 100%;
                    }

                    @include bp-max-420 {
                        @include order(2);
                        margin-bottom: 20px;
                    }

                    .has-quad__copy {
                        @include bp-max-420 {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }

            &.has-quad__quad--right {
                .col2-eq__l {
                    @include bp-max-1024 {
                        padding: 0px 30px;
                        // width: 50%;
                        width: 100%;
                    }

                    @include bp-max-768 {
                        padding: 0px 30px;
                        width: 100%;
                        padding-bottom: 30px;
                    }

                    @include bp-max-420 {
                        padding: 0px 30px;
                    }

                    .has-quad__copy {
                        @include bp-max-420 {
                            padding: 0;
                        }
                    }
                }

                .col2-eq__r {
                    @include bp-max-1024 {
                        margin-right: 0;
                        width: 50%;
                    }

                    @include bp-max-768 {
                        @include order(1);
                        margin: 0;
                        // width: 50%;
                        width: 100%;
                    }

                    @include bp-max-420 {
                        width: 100%;
                    }
                }
            }
        }

        &.has-gray-box-w-photo-upper-left-corner {
            @include bp-max-1024 {
                @include flex-wrap(wrap);
                margin-left: 20px;
            }

            .col2-eq__l,
            .col2-eq__r {
                background-color: $lightGray;
                @include flex-direction(row);
                @include align-items(flex-start);
                @include font-size(1.6);
                margin: 0;
                padding: 0;

                @include bp-max-420 {
                    @include flex-wrap(wrap);
                }

                > img {
                    // @include flex(1 1 40%);
                    position: relative;
                    left: -20px;
                    top: -20px;
                    // width: 100%;
                    max-width: 50%;

                    @include bp-max-420 {
                        max-width: 100%;
                    }
                }

                .col2-eq__l__copy,
                .col2-eq__r__copy {
                    @include flex(1 1 50%);
                    padding: 20px 20px 20px 0px;

                    @include bp-max-420 {
                        @include flex(1 1 100%);
                        padding: 20px 15px;
                    }

                    h3 {
                        @include font-size(2);
                        font-family: $font-main-semibold;
                        text-transform: uppercase;
                    }

                    p {
                        @include font-size(1.6);
                    }
                }
            }

            .col2-eq__l {
                margin-right: 40px;

                @include bp-max-1024 {
                    margin-right: 0;
                    margin-bottom: 40px;
                    width: 100%;
                }
            }

            .col2-eq__r {
                @include bp-max-1024 {
                    width: 100%;
                }
            }
        }

        &.has-gray-box-w-photo-upper-right-corner {
            @include bp-max-1024 {
                @include flex-wrap(wrap);
                margin-right: 20px;
            }

            .col2-eq__l,
            .col2-eq__r {
                background-color: $lightGray;
                @include flex-direction(row);
                @include align-items(flex-start);
                @include font-size(1.6);
                margin: 0;
                padding: 0;

                @include bp-max-1024 {
                    width: 100%;
                }

                @include bp-max-420 {
                    @include flex-wrap(wrap);
                }

                > img {
                    // @include flex(1 1 40%);
                    position: relative;
                    left: 20px;
                    top: -20px;
                    // width: 100%;
                    max-width: 50%;

                    @include bp-max-420 {
                        @include order(1);
                        max-width: 100%;
                    }
                }

                .col2-eq__l__copy,
                .col2-eq__r__copy {
                    @include flex(1 1 50%);
                    padding: 20px 0px 20px 20px;

                    @include bp-max-420 {
                        @include flex(1 1 100%);
                        @include order(2);
                    }

                    h3 {
                        @include font-size(2);
                        font-family: $font-main-semibold;
                        text-transform: uppercase;
                    }

                    p {
                        @include font-size(1.6);
                    }
                }
            }

            .col2-eq__l {
                margin-right: 40px;

                @include bp-max-1024 {
                    margin-right: 0;
                    margin-bottom: 40px;
                }

                @include bp-max-420 {
                    margin-right: 0;
                    margin-bottom: 40px;
                }
            }
        }

        .col2-eq__l {
            margin-left: 50px;

            @include bp-max-1024 {
                margin-left: 0;
                padding-right: 0;
                width: 100%;
            }

            @include bp-max-768 {
                margin-left: 30px;
                padding-right: 0;
            }

            @include bp-max-420 {
                margin-left: 15px;
            }

            .has-quad__copy {
                @include bp-max-420 {
                    margin-left: 15px;
                    margin-right: 15px;
                }
            }

            &.col2-eq__l--75 {
                margin-left: 75px;

                @include bp-max-1024 {
                    margin-left: 20px;
                }

                @include bp-max-768 {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }

            &.col2-eq__l--110 {
                margin-left: 110px;

                @include bp-max-1024 {
                    margin-left: 30px;
                }
            }
        }

        .col2-eq__r {
            margin-right: 40px;
            padding-right: 0px;

            @include bp-max-420 {
                margin-left: 15px;
                margin-right: 15px;
            }

            &.col2-eq__r--has-image {
                @include order(1);
            }


            &.col2-eq__r--180 {
                margin-right: 180px;

                @include bp-max-1024 {
                    margin-right: 30px;
                }
            }

            &.col2-eq__r--75 {
                margin-right: 75px;

                @include bp-max-1024 {
                    margin-right: 30px;
                }
            }
        }
    }

    h2 {
        font-family: $font-main-book;

        @include bp-max-420 {
            margin: 15px 0px;
            padding: 0px 30px;
            font-family: $font-main-medium;
        }
    }

    p {
        font-family: $font-main-book;
        line-height: 1.5em;

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }
}

.row-of-cards {
    @include flexbox;
    @include justify-content(space-between);
    margin: 20px 100px;

    @include bp-min-1920 {
        @include justify-content(space-evenly);
        margin-left: 150px;
        margin-right: 150px;
    }

    @include bp-max-1024 {
        margin: 20px;
    }

    @include bp-max-768 {
        overflow-x: scroll;
        margin-left: 30px;
        margin-right: 0;
    }

    h2 + & {
        margin-top: 60px;

        @include bp-max-420 {
            margin-top: 40px;
        }
    }

    & + a.btn--big {
        margin-top: 100px;
        top: 70px;

        @include bp-max-768 {
            margin-top: 30px;
            margin-bottom: 100px;
        }

        @include bp-max-420 {
            font-size: 36px;
            margin-bottom: 30px;
            max-width: 90%;
            padding: 40px 0;
            text-align: center;
            top: 50px;
            width: 100%;
        }
    }

    &.row-of-cards--mobile-horizontal-scroll {
        @include bp-max-1024 {
            padding: 0px 10px;
        }

        .row-of-cards__card {
            margin-right: 20px;
        }
    }

    .row-of-cards__card {
        background-color: $white;
        border: 1px solid $lightGray;
        box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.3);
        @include flex(0 1 280px);
        margin-right: 20px;
        padding-bottom: 20px;

        @include bp-min-1920 {
            @include flex(0 1 415px);
        }

        @include bp-max-768 {
            @include flex(1 0 280px);
            margin-right: 20px;
        }

        @include bp-max-420 {
            padding-top: 10px;
            margin-right: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        > a,
        .row-of-cards__card--not-linked {
            display: block;
            height: 100%;
            padding: 20px;

            @include bp-max-420 {
                padding-top: 10px;
            }

            > img,
            svg {
                display: block;
                margin: 0 auto;
                @include flex(0 0 auto);
                width: 94px;
            }

            .row-of-cards__card__title {
                color: $black;
                font-family: $font-main-semibold;
                @include font-size(2);
                margin: 20px 0;
                text-align: center;
                text-transform: uppercase;

                @include bp-max-1024 {
                    @include font-size(2);
                }

                @include bp-max-420 {
                    @include font-size(1.8);
                }
            }

            .row-of-cards__card__copy {
                line-height: 1.5em;
            }

            ul {
                list-style-type: disc;
                padding-left: 30px;
                line-height: 1.2em;

                @include bp-max-1024 {
                    @include font-size(1.6);
                }

                > li {
                    margin-bottom: 5px;

                    @include bp-max-1024 {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .row-of-cards__card--has-btn {
            @include flexbox;
            @include flex-direction(column);

            .row-of-cards__card__title {
                margin-bottom: 40px;
            }

            .row-of-cards__card__copy {
                @include flexbox;
                @include flex-direction(column);
                // @include flex(1 1 100%);
                height: 100%;

                > p:first-of-type {
                    // @include flex(1 1 100%);
                    margin-bottom: 20px;
                }

                > a {
                    // margin-top: auto;
                    text-align: center;
                    width: 100%;
                }
            }
        }

        &.row-of-cards__card--not-visible {
            display: none;
        }
    }

    &.row-of-cards--in-the-news {
        @include flex-wrap(wrap);
        @include justify-content(flex-start);
        margin-left: 0;
        margin-right: 0;

        @include bp-max-1024 {
            // @include flex-wrap(nowrap);
            // overflow-x: scroll;
            @include justify-content(space-between);
            margin-left: 10px;
            margin-right: 10px;
        }

        .row-of-cards__card {
            @include flex(1 1 330px);
            margin-bottom: 20px;

            @include bp-min-1920 {
                @include flex(1 1 300px);
            }

            @include bp-max-1024 {
                @include flex(1 0 350px);
            }

            @include bp-max-420 {
                @include flex(1 1 100%);
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: auto !important;
            }

            > a {
                font-family: $font-zh;
                @include font-size(1.8);
                text-align: left;

                > div:first-of-type {
                    color: $darkGray;
                    font-family: $font-main-book;
                }

                .row-of-cards__card__title {
                    font-family: $font-zh;
                    font-weight: bold;
                    @include font-size(2.4);
                    text-align: left;

                    @include bp-max-420 {
                        @include font-size(1.6);
                    }
                }

                > p:last-of-type,
                p {
                    font-family: $font-zh;
                    @include font-size(1.4);
                    font-weight: normal;
                }
            }

            > .row-of-cards__card__title {
                font-weight: bold;
                @include font-size(2.4);

                @include bp-max-420 {
                    @include font-size(1.6);
                }
            }

            .row-of-cards__card--not-linked {
                > p {
                    font-family: $font-zh;
                    @include font-size(1.8);
                    text-align: left;

                    > span {
                        font-weight: normal;
                    }
                }

                > .row-of-cards__card__title {
                    font-weight: bold;
                    @include font-size(2.4);
                }
            }
        }
    }

    &.row-of-cards--no-outline {
        @include justify-content(center);
        @include flex-wrap(wrap);
        margin: 20px 0px;

        .row-of-cards__card {
            box-shadow: none;
            border: none;
            border-right: 1px solid $lightGray;
            @include flex(0 1 350px);
            margin-bottom: 40px;
            max-width: 450px;

            @include bp-max-420 {
                border-right: none;
            }

            .row-of-cards__card--not-linked {
                margin-top: 0;
                padding: 0px 20px;

                .row-of-cards__card__title {
                    margin-top: 0;
                    text-align: left;
                }
            }

            &.row-of-cards__card:last-of-type {
                border-right: 0;
                margin-right: 20px;
            }
        }
    }
}

.panel-group {
    h2 + & {
        margin-top: 40px;
    }

    .panel {
        border-top: 1px solid $lightGray;
        cursor: pointer;
        height: 50px;
        overflow: hidden;
        padding: 10px 0px;
        position: relative;
        transition: all 500ms;

        &:before {
            background-color: $red;
            content: "";
            height: 8px;
            position: absolute;
            top: 18px;
            right: 1px;
            width: 26px;
        }

        &:after {
            background-color: $red;
            content: "";
            height: 26px;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 8px;
            transition: all 400ms;
            transform-origin: center center;
        }

        &.panel--is-open {
            //height: 1000px;
            &:after {
                transform: scaleY(0);
            }
        }

        .panel-title {
            font-family: $font-main-semibold;
            margin-bottom: 20px;

            @include bp-max-420 {
                margin-right: 20px;
            }

            a {
                color: $red;
            }
        }

        .panel-collapse {
            .panel-body {
                .panel-body__row {
                    @include flexbox;
                    margin-bottom: 20px;

                    > strong:first-of-type {
                        color: $black;
                        @include flex(0, 0, 110px);
                        transition: all 400ms;
                    }

                    p {
                        color: $red;
                        transition: all 400ms;
                    }

                    &:hover {
                        > strong:first-of-type {
                            color: darken($red, 20%);
                        }

                        p {
                            color: darken($red, 20%);
                        }
                    }

                    &.panel-body__row--not-linked {
                        p {
                            color: $gunmetal;
                        }
                    }
                }
            }
        }
    }
}
