$white: #fff;
$black: #000;
$red: #ce1b0e;
$gunmetal: #54585a;
$lightGray: #ebebeb;
$gray: #d7d7d7;
$darkGray: #333;

$font-main-light: "Whitney-Light", Arial, sans-serif;

$font-main-book: "Whitney-Book", Arial, sans-serif;

$font-main-medium: "Whitney-Medium", Arial, sans-serif;

$font-main-semibold: "Whitney-Semibold", Arial, sans-serif;

$font-main-semibold-italic: "Whitney-SemiboldItalic", Arial, sans-serif;

$font-zh: "Microsoft YaHei", "Hiragino Sans GB", "WenQuanYi Micro Hei",
  sans-serif;

$italics: italic;
