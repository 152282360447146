/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />

//Hamburger menu
#page-trans {
  backface-visibility: hidden;
  background-color: $gunmetal;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform-origin: left 50% 0;
  transform-origin: left 50% 0;
  -webkit-transition: all 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  transition: all 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  width: 0;
  z-index: 101;
}

.hamburger-menu {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  width: 100%;
  z-index: 99;

  .hamburger-menu__interior {
    display: none;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0;
    z-index: -1;

    ul.hamburger-menu__interior__items {
      margin-top: 125px;
      padding: 0;
      position: relative;

      .hamburger-menu__item {
        display: block;
        //                opacity: 0;
        //                text-align: center;
        position: relative;
        padding-left: 28.56vw;

        a {
          display: inline-block;
          @include font-size(3.6);

          .hamburger-menu__item--main {
            display: inline-block;
            position: relative;
            text-align: center;
          }

          .hamburger-menu__item--main:after {
            background-color: $gunmetal;
            content: "";
            left: -5px;
            right: -15px;
            display: block;
            height: 2px;
            position: absolute;
            top: calc(60% + 3px);
            transition: width 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            width: 0;
          }
        }

        a:hover {
          span {
            color: hsla(0, 0%, 100%, 0.5);
          }

          span.hamburger-menu__item--main:after {
            width: 110%;
          }
        }

        span {
          color: hsla(0, 0%, 100%, 0.2);
          transition: color 0.6s;
        }

        a:hover + .hamburger-menu__item__bckgd {
          opacity: 0.1;
          -webkit-transform: perspective(500px) translateZ(50px);
          transform: perspective(500px) translateZ(50px);
        }

        .hamburger-menu__item__description {
          position: absolute;
          top: 0;
          left: 57.12vw;

          span:first-of-type {
            opacity: 0;
            position: absolute;
            top: 0;
            left: -80px;
            width: 30px;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
          }

          .nav__interior__items--subheading {
            opacity: 0;
            position: relative;
            left: -40px;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
          }

          .nav__item__description__text {
            color: rgba(255, 255, 255, 0.5);
            display: block;
            @include font-size(2.1);
            opacity: 0;
            position: relative;
            left: 0;
            top: -20px;
            width: 28.56vw;
            -webkit-transition: all 0.6s;
            transition: all 0.6s;
          }
        }
      }

      .nav__item > a:hover ~ .nav__item__description > span:first-of-type {
        opacity: 1;
        left: -50px;
      }

      .nav__item > a:hover ~ .nav__item__description > span:nth-of-type(2) {
        opacity: 1;
        left: 0px;
      }
      .nav__item > a:hover ~ .nav__item__description > span:nth-of-type(3) {
        opacity: 1;
        top: 0px;
      }
    }
  }

  .hamburger-menu__interior.is-open {
    background-color: darken($gunmetal, 10%);
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    pointer-events: all;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;

    .nav__item:nth-of-type(2) {
      transition: opacity 0.4s ease-in 1.2s;
    }

    .nav__item {
      opacity: 1;
    }
  }
}

//Flexbox slider
.scroller-wrap {
  @include flexbox;
  @include align-items(center);
  width: 100%;
  margin: auto;

  button {
    border: solid $red;
    border-width: 0 3px 3px 0;
    background: transparent;
    cursor: pointer;
    height: 30px;
    opacity: 1;
    padding: 3px;
    width: 30px;
    transition: all 300ms ease-in-out;

    &:hover {
      border-color: darken($red, 10%);
    }

    &:focus {
      border-bottom-right-radius: 6px;
      border-color: lighten($red, 10%);
      outline: none;
    }

    &.scroller__button--hide {
      cursor: auto;
      opacity: 0;
    }
  }

  .right {
    @include rotate-caret(right);
    &:hover {
      transform: rotate(-45deg) translateX(5px) translateY(5px);
    }
  }

  .left {
    @include rotate-caret(left);
    &:hover {
      transform: rotate(135deg) translateX(5px) translateY(5px);
    }
  }

  .scroller {
    flex: 1;
    overflow: hidden;
  }

  .items {
    list-style-type: none;
    white-space: nowrap;
    font-size: 0;
    line-height: 0;
    padding: 0;
    transition: transform 1s cubic-bezier(0.68, -0.21, 0.31, 1);
  }

  .item {
    padding: 0px 10px;
    display: inline-block;
    // width: 100%;

    img {
      max-width: 100%;
    }
  }
}

// Perspective animation on images
.anim-perspective-wrap {
  perspective: 220px;
}
.anim-perspective {
  transition: transform 500ms;
}
