/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
.hero-wrap {
  padding: 0 18px;
  position: relative;
  width: 100%;

  &:after {
    // content: "";
    position: absolute;
    bottom: 9px;
    left: -100px;
    width: 100%;
    height: 25px;
    border-radius: 100%;
    z-index: 9999;
    background: radial-gradient(
      ellipse at center,
      rgba(155, 155, 155, 0.33) 0%,
      rgba(255, 255, 255, 0) 61%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @include bp-min-1920 {
    padding: 0 80px;
  }

  .slider {
    @include flexbox;
    overflow: hidden;
    margin-bottom: 16px;
    padding-bottom: 40px;
    position: relative;

    @include bp-max-1024 {
      padding-bottom: 10px;
    }

    @include bp-max-768 {
      padding-bottom: 6px;
    }

    @include bp-max-420 {
      padding-bottom: 20px;
    }

    #quad-effect {
      background-color: white;
      display: none;
      height: 100%;
      position: absolute;
      top: 93%;
      left: 0px;
      width: 105%;
      z-index: 1;
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
      -ms-transform-origin: top left;
      transform-origin: top left;

      .no-clip-path & {
        display: block;
      }

      @include bp-max-1024 {
        top: 96%;
        left: -5px;
      }

      @include bp-max-768 {
        top: 99%;
      }

      @include bp-max-420 {
        top: 95%;
      }
    }

    .slider__slide {
      // @include backImage(
      //   "../img/loading.png",
      //   no-repeat,
      //   539px,
      //   center center,
      //   cover
      // );
      background-color: $black;
      @include flexbox;
      @include align-items(flex-start);
      margin-left: 10px;
      opacity: 0.25;
      overflow-x: hidden;
      position: relative;
      // -webkit-clip-path: polygon(0 0, 7% 0, 7% 99%, 0% 100%);
      // clip-path: polygon(0 0, 7% 0, 7% 99%, 0% 100%);
      width: 250px;
      // @include flex(1 1 33%);
      transition: all 750ms cubic-bezier(0.74, 0.26, 0.28, 0.99);

      @include bp-min-1920 {
        width: 500px;
      }

      @include bp-max-768 {
        // background-position: top left;
        width: 125px;
      }

      @include bp-max-420 {
        // background-size: cover;
        @include align-items(flex-end);
        height: 390px;
        margin-left: 0;
        width: 0px;

        // @include flex(0 0 0px);
        // margin-left: 0px;
      }

      &.slider__slide--page-load {
        // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
        // clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
        flex-grow: 2;
        width: 100%;
        // @include flex(1 1 100%);
        opacity: 1;

        @include bp-min-1920 {
          width: 100%;
        }

        //& + .slider__slide:nth-child(2) {
        //selects the 2nd slide child (and the first's adjacent sibling) while hovering
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 86.75%);
        // clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 86.75%);

        //& + .slider__slide:nth-child(3) {
        //selects the 3rd slide child (and the first's adjacent sibling) while hovering
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
        // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
        //}
        //}
      }

      &:first-of-type {
        margin: 0;
        // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 98%, 0% 99.7%);
        // clip-path: polygon(0 0, 100% 0%, 100% 98%, 0% 99.7%);

        //&.slider__slide--page-load {
        // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 86.75%, 0% 99.7%);
        // clip-path: polygon(0 0, 100% 0%, 100% 86.75%, 0% 99.7%);
        //}

        &:hover {
          width: 100%;
          // @include flex(1 1 100%);
          opacity: 1;
          // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
          // clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);

          //& + .slider__slide:nth-child(2) {
          //selects the 2nd slide child (and the first's adjacent sibling) while hovering
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0% 90%);
          // clip-path: polygon(0 0, 100% 0, 100% 88%, 0% 90%);

          //& + .slider__slide:nth-child(3) {
          //selects the 3rd slide child (and the first's adjacent sibling) while hovering
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
          // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
          //}
          //}

          @include bp-max-420 {
              width: 0;
          }
        }
      }

      &:nth-child(2) {
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 96.25%, 0% 98%);
        // clip-path: polygon(0 0, 100% 0, 100% 96.25%, 0% 98%);
        // @include backImage(
        //   "../img/hp-hero-slide2.png",
        //   no-repeat,
        //   539px,
        //   center center,
        //   cover
        // );

        &.slider__slide--page-load {
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 98%);
          // clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 98%);
          // @include flex(1 1 100%);
          opacity: 1;

          //& + .slider__slide:nth-child(3) {
          //selects the 3rd slide child (and the first's adjacent sibling) while hovering
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
          // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
          //}
        }

        &:hover {
          // @include flex(1);
          width: 100%;
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 98%);
          // clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 98%);
          opacity: 1;

          //&:nth-child(1) {
          //selects the 1st slide child (and the this slide (second)'s adjacent sibling) while hovering
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);
          // clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);
          //}

          //& + .slider__slide:nth-child(3) {
          //selects the 3rd slide child (and the first's adjacent sibling) while hovering
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 783.25%, 0% 84.9%);
          // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 84.9%);
          //}

          @include bp-max-420 {
              width: 0;
          }
        }

        @include bp-max-420 {
          height: 390px;
        }
      }

      &:nth-child(3) {
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 96.3%);
        // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 96.3%);
        // @include backImage(
        //   "../img/hp-hero-slide3.png",
        //   no-repeat,
        //   539px,
        //   center center,
        //   cover
        // );

        &:hover {
          width: 100%;
          // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 96.3%);
          // clip-path: polygon(0 0, 100% 0, 100% 83.25%, 0% 96.3%);
          opacity: 1;

          @include bp-max-420 {
              width: 0;
          }
        }
        //&.slider__slide--page-load {
        // @include flex(1 1 100%);
        // opacity: 1;
        //}

        @include bp-max-420 {
          height: 390px;
        }
      }

      //&:hover {
      // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
      // clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
      //}

      &.no-object-fit {
        background-size: cover;
        background-repeat: no-repeat;

        img.lazy {
          opacity: 0;
        }
      }

      .slider__slide__content {
        @include flexbox;
        @include flex-direction(column);
        @include flex(0 0 100vw);
        @include align-items(flex-start);
        height: 100%;
        padding: 220px 48px 95px 58px;
        width: 100vw;
        z-index: 2;

        @include bp-min-1920 {
          padding-bottom: 75px;
        }

        @include bp-max-768 {
          padding-bottom: 60px;
          padding-left: 20px;
          padding-top: 275px;
        }

        @include bp-max-420 {
          // @include flex(1 1 100%);
          height: auto;
          padding: 10px 20px 40px;
          // width: 100%;
        }

        span.slide__heading {
          color: $white;
          display: block;
          @include font-size(4.2);
          font-family: $font-main-semibold;
          line-height: 1.2em;
          margin-bottom: 10px;
          text-transform: uppercase;
          width: 57%;

          @include bp-min-1920 {
            @include font-size(4.8);
            width: 44%;
          }

          @include bp-max-768 {
            @include font-size(3);
            //margin-top: auto;
            width: 70%;
          }

          @include bp-max-420 {
            @include font-size(2.5);
            width: 94%;
          }
        }

        .slide__copy {
          margin-bottom: 30px;
          width: 50%;

          @include bp-min-1920 {
            width: 42%;
          }

          @include bp-max-768 {
            margin-bottom: 10px;
            width: 70%;
          }

          @include bp-max-420 {
            @include font-size(1.6);
            margin-bottom: 30px;
            width: 92%;
          }

          p {
            color: $white;

            @include bp-min-1920 {
              @include font-size(2);
            }
          }
        }

        .btn-solid {
          margin-top: auto;

          @include bp-max-768 {
            margin-top: auto;
          }

          @include bp-max-420 {
            // margin-top: auto;
          }
        }
      }

      > img.lazy {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .slide__overlay {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.9)
        );
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
    }

    .slider__slide:nth-child(1):hover {
      width: 100%;
      // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 86.75%, 0% 99.7%);
      // clip-path: polygon(0 0, 100% 0%, 100% 86.75%, 0% 99.7%);

      //& + &:nth-child(2) {
      //selects the 2nd slide child (and the first's adjacent sibling) while hovering
      // -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0% 90%);
      // clip-path: polygon(0 0, 100% 0, 100% 88%, 0% 90%);
      //}
    }

    //.slider__slide:nth-child(1):hover + .slider__slide:nth-child(2) {
    //selects the 2nd slide child (and the first's adjacent sibling) while hovering
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 86.75%);
    // clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 86.75%);
    //}

    .slider__controls {
      @include flexbox;
      position: absolute;
      bottom: 0px;
      left: 57px;
      z-index: 2;

      @include bp-min-1920 {
        left: 57px;
      }

      @include bp-max-1024 {
        left: initial;
        right: 0;
      }

      @include bp-max-420 {
        left: calc(50% - 121px);
        right: initial;
      }

      .slider__controls__control {
        border: 1px solid $red;
        cursor: pointer;
        height: 5px;
        margin-right: 10px;
        position: relative;
        width: 74px;
        transition: all 250ms ease-in-out;

        &:last-of-type {
          margin-right: 0;
        }

        &:after {
          border-bottom: 3px solid transparent;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          transition: all 250ms ease-in-out;
        }

        &:hover {
          &:after {
            // border-bottom: 3px solid $red;
            width: 100%;
          }
        }
      }

      .slider__controls__control--active {
        // background-color: $red;
        &:after {
          border-bottom: 3px solid $red;
          width: 100%;
        }

        // &:hover {
        //   background-color: $red;
        // }
      }
    }
  }

  .wechat-wrap {
    @include flexbox;
    @include align-items(flex-end);
    position: absolute;
    right: 48px;
    bottom: 0;
    z-index: 2;

    @include bp-min-1920 {
      right: 56px;
    }

    &.mobile--not-visible {
      @include bp-max-1024 {
        display: none;
      }
    }

    .wechat__text {
      text-align: right;
      p {
        @include font-size(1.4);
        line-height: 1.5em;
      }
    }

    .wechat__code {
      margin-left: 10px;
    }
  }
}

//Chinese tweaks
.zh {
  .hero-wrap {
    .slider {
      .slider__slide {
        .slider__slide__content {
          @include bp-min-1920 {
            padding-top: 230px;
          }
        }
      }
    }
  }
}
