/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />

@font-face {
  font-family: "Whitney-Light";
  src: url("../fonts/Whitney-Light.eot");
  src: url("../fonts/Whitney-Light.woff2") format("woff2"),
    url("../fonts/Whitney-Light.woff") format("woff"),
    url("../fonts/Whitney-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney-Book";
  src: url("../fonts/Whitney-Boom.eot");
  src: url("../fonts/Whitney-Book.woff") format("woff"),
    url("../fonts/Whitney-Book.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney-Medium";
  src: url("../fonts/Whitney-Medium.eot");
  src: url("../fonts/WhitneySSm-Medium-Pro.otf") format("opentype");
  src: url("../fonts/Whitney-Medium.woff2") format("woff2"),
    url("../fonts/Whitney-Medium.woff") format("woff"),
    url("../fonts/Whitney-Medium.ttf") format("truetype"),
    url("../fonts/Whitney-Medium.svg#Whitney-Medium") format("svg"),
    url("../fonts/Whitney-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney-Semibold";
  src: url("../fonts/Whitney-Semibold.eot");
  src: url("../fonts/WhitneySSm-Semibold-Pro.otf") format("opentype");
  src: url("../fonts/Whitney-Semibold.woff2") format("woff2"),
    url("../fonts/Whitney-Semibold.woff") format("woff"),
    url("../fonts/Whitney-Semibold.ttf") format("truetype"),
    url("../fonts/Whitney-Semibold.svg#Whitney-Semibold") format("svg"),
    url("../fonts/Whitney-Semibold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney-SemiboldItalic";
  src: url("../fonts/Whitney-SemibldItal.eot");
  src: url("../fonts/Whitney-SemibldItal.woff2") format("woff2"),
    url("../fonts/Whitney-SemibldItal.woff") format("woff"),
    url("../fonts/Whitney-SemibldItal.ttf") format("truetype"),
    url("../fonts/Whitney-SemibldItal.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney-Bold";
  src: url("../fonts/Whitney-Bold.eot");
  src: url("../fonts/WhitneySSm-Bold-Pro.otf") format("opentype");
  src: url("../fonts/Whitney-Bold.woff2") format("woff2"),
    url("../fonts/Whitney-Bold.woff") format("woff"),
    url("../fonts/Whitney-Bold.ttf") format("truetype"),
    url("../fonts/Whitney-Bold.svg#Whitney-Bold") format("svg"),
    url("../fonts/Whitney-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
