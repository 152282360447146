/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

.rf-geren-energy-video {
  cursor: pointer;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
  @include backImage(
    "../img/hp-video-placeholder.jpg",
    no-repeat,
    center center,
    cover,
    539px,
    0.9
  );

  @include bp-max-420 {
    -webkit-clip-path: none;
    clip-path: none;
  }
}
