/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

.subpage-hero {
    &.hero-wrap {
        background-position: top left;
        background-size: cover;
        height: 453px;
        @include flexbox;
        @include justify-content(center);
        @include align-items(flex-end);
        padding: 0;

        @include bp-max-1024 {
            height: 300px;
        }

        @include bp-max-768 {
            height: 250px;
        }

        & + .not-full-width {
            margin-top: 50px;

            & + .tabbed-content--has-fade-in-out {
                .not-full-width {
                    h2 {
                        margin-top: 100px;
                    }
                }
            }
        }

        &.our-presence {
            @include backImage( "../img/subpage-hero-our-presence.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.careers {
            @include backImage( "../img/subpage-hero-careers.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.media-center {
            @include backImage( "../img/subpage-hero-media-center.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.art-program {
            @include backImage( "../img/subpage-hero-art-program.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.working-captial {
            @include backImage( "../img/subpage-hero-working-capital.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.trade-finance {
            @include backImage( "../img/subpage-hero-trade-finance.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.cash-management {
            @include backImage( "../img/subpage-hero-cash-management.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.cross-border-banking {
            @include backImage( "../img/subpage-hero-cross-border-banking.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.entertainment {
            @include backImage( "../img/subpage-hero-entertainment.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.high-tech-banking {
            @include backImage( "../img/subpage-hero-high-tech-banking.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }

            @include bp-max-420 {
                background-position-x: -150px !important;
                background-size: 260%;
            }
        }

        &.online-banking {
            @include backImage( "../img/subpage-hero-online-banking.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }

        &.rates {
            @include backImage( "../img/subpage-hero-rates.jpg", no-repeat, center center, cover, 453px, 0.9 );

            @include bp-max-768 {
                height: 250px;
            }
        }
    }
    // @include backImage( "../img/subpage-hero-entertainment.jpg", no-repeat, left top, cover, 453px, 0);
    .subpage-hero__title {
        @include font-size(4.2);
        color: $white;
        font-family: $font-main-semibold;
        letter-spacing: 0;
        margin-bottom: 100px;
        text-align: center;
        text-transform: uppercase;
        width: 75%;

        @include bp-max-1024 {
            @include font-size(3.6);
            width: 90%;
        }

        @include bp-max-768 {
            @include font-size(2.7);
            margin-bottom: 40px;
        }
    }
}

.hero-wrap {
  &.hero-wrap--with-video {
    @include flexbox;
    @include align-items(flex-end);
    @include flex-wrap(wrap);
    padding: 0;

    @include bp-max-768 {
      @include flex-wrap(wrap);
    }

    .subpage-hero__title {
      color: $gunmetal;
      font-family: $font-main-light;
      @include font-size(4.8);
      letter-spacing: 0;
      text-transform: none;

      @include bp-max-1024 {
        @include font-size(2.7);
      }

      @include bp-max-768 {
        @include font-size(3);
      }

      @include bp-max-420 {
        @include font-size(2.4);
      }
    }

    p {
      font-family: $font-main-light;
      line-height: 1.5em;
      > span {
        font-style: italic;
      }
    }

    .hero-wrap--with-video__text {
      @include flex(1 1 30%);
      padding-left: 75px;
      margin-right: 50px;

      @include bp-max-768 {
        @include flex(1 1 100%);
        @include order(2);
        margin-top: 20px;
        padding: 0 30px;
      }
    }

    .hero-wrap--with-video__video {
      cursor: pointer;
      @include flexbox;
      @include justify-content(center);
      @include flex(1 1 60%);
      // @include align-items(center);
      overflow: hidden;
      padding-right: 0;
      position: relative;
      transition: all 500ms;

      @include bp-max-768 {
        padding: 30px 0;
      }

      &.nasdaq-2019-bell-ringing-video {
          @include backImage(
            "../img/nasdaq-image-placeholder.jpg",
            no-repeat,
            center center,
            cover,
            539px,
            0.9
          );

          video {
              opacity: 0;
          }

          @include bp-max-420 {
              height: 240px;
          }
      }

      &.has-quad__video--is-playing {
        background: $black;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        width: 82%;

        @include bp-min-1920 {
          width: 70%;
        }

        @include bp-max-1024 {
          margin-right: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 2;
        }

        @include bp-max-420 {
          position: relative;
        }

        video {
          opacity: 1;
        }

        .vid__close {
          display: block;
          top: 30px;
          right: 20px;
          left: inherit;
          z-index: 1;
        }

        .has-quad__video__labels {
          // left: -100%;
          opacity: 0;
        }
      }

      .vid__close {
        display: none;
      }

      video {
        // max-height: 430px;
        // opacity: 0;
        width: 100%;
        transition: all 500ms;

        @include bp-max-1024 {
          max-height: inherit;
          width: 100%;
        }
      }

      .has-quad__video__labels {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        opacity: 1;
        padding: 0 20px;
        position: absolute;
        left: calc(50% - 75px);
        top: calc(50% - 86px);
        text-align: center;
        transition: opacity 300ms cubic-bezier(0.56, 0.01, 0.35, 1);

        @include bp-max-1024 {
          left: 0;
          width: 100%;
        }

        @include bp-max-420 {
          top: calc(50% - 74px);
        }

        p {
          color: #fff;
          font-family: "Whitney-Semibold", Arial, "Hiragino Sans GB",
            "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
          font-weight: 700;
          // width: 60%;

          @include bp-max-420 {
            @include font-size(1.6);
            width: 100%;
          }
        }

        .btn-video-play + p {
          margin-top: 20px;
          @include font-size(1.8);
        }
      }

      &.has-quad__quad--slant-left {
        -webkit-clip-path: polygon(5% 0, 100% 0%, 100% 100%, 15% 100%);
        clip-path: polygon(5% 0, 100% 0%, 100% 100%, 15% 100%);
        transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1.01);
        max-width: 1200px;

        @include bp-max-768 {
          -webkit-clip-path: none;
          clip-path: none;
        }

        &:hover {
          -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          width: 82%;

          @include bp-min-1920 {
            width: 70%;
          }

          &.has-quad__video--is-playing {
            @include bp-max-1024 {
              width: 100%;
            }
          }
        }
      }
    }

    .col2-eq--text-next-to-image {
      margin-bottom: 100px;

      @include bp-max-768 {
        @include order(3);
      }
    }
  }
}
