/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

.carousel.news {
  // margin: 52px auto;
  // border-right: 1px solid lightgray;
  // box-shadow: inset -4px 0px 4px -3px rgba(0, 0, 0, 0.2);
  height: 232px;

  @include bp-max-420 {
    height: 260px;
  }

  .item {
    // background-color: rgba(240, 128, 128, 0.2);
    border-right: 1px solid $lightGray;
    cursor: pointer;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    padding: 10px 40px 0px 0px;
    width: 185px;
    height: 100%;

    @include bp-min-1920 {
      width: 190px;
    }

    @include bp-max-420 {
      width: 150px;
    }

    &:hover {
      > img:first-of-type {
        opacity: 0.75;
        transform: scale(1.1);
      }

      p,
      span {
        color: $red;
      }

      i {
        margin-left: 5px;
      }
    }

    > img:first-of-type {
      margin-bottom: 21px;
      max-height: 68px;
      transition: transform 250ms ease-in-out;
    }

    > p:first-of-type {
      font-family: $font-zh;
      line-height: 1.1em;
      margin-bottom: 21px;
      white-space: normal;
      width: 100%;
      transition: color 250ms;
    }

    > div:nth-child(3) {
      // height: 36px;
      margin-top: auto; //positions it on the bottom in a flex col setup
      width: 100%;

      @include bp-max-420 {
        height: 75px;
      }

      > p {
        color: $black;
        @include font-size(1.4);
      }
      > span {
        color: $black;
        @include font-size(1.4);

        span {
          transition: color 250ms;
        }

        i {
          transition: margin 250ms 250ms;

          &:hover & {
            margin-left: 20px;
          }
        }
      }
    }

    &.is-selected {
      padding-left: 0;
    }
  }
}

.carousel {
    &.employee-happenings {
        .item {
            width: 50%;

            @include bp-min-1920 {
                width: 33%;
            }

            @include bp-max-1024 {
                width: 33%;
            }

            @include bp-max-768 {
                width: 50%;
            }

            @include bp-max-420 {
                width: 100%;
            }

            > img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .item__cta {
        @include font-size(1.4);
    }
}

body.zh {
  .carousel.news .item > div:nth-child(3) {
    height: 60px;
  }
}
