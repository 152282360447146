/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
.mega__wrap {
  position: relative;
  z-index: 1;

  .mega__overlay {
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
    // opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    // transition: all 500ms ease-in-out;

    &.mega__overlay--active {
      display: block;
      opacity: 1;
    }
  }
  .mega__content {
    background-color: $lightGray;
    @include flexbox;
    @include align-items(stretch);
    min-height: 250px;
    position: absolute;
    top: -800px;
    left: 0;
    width: 100vw;
    z-index: 2;
    transition: all 500ms cubic-bezier(0.79, 0.09, 0, 1.01);

    @include bp-min-1920 {
      top: -650px;
      max-width: 1920px;
    }

    @include bp-max-1024 {
      background-color: $white;
      @include flex-wrap(wrap);
      padding-top: 40px;
      top: -1000px;
      z-index: 10;
    }

    @include bp-max-420 {
      display: block;
      height: calc(100vh - 80px);
      min-height: initial;
      overflow-y: scroll;
      padding-top: 0;
    }

    &.mega__content--active {
      top: 0px;
    }

    > .mega__section-labels {
      @include bp-max-1024 {
        border-bottom: 1px solid $lightGray;
      }
    }

    .mega__dropdown {
      border-bottom: 1px solid $lightGray;
      @include flexbox;
      @include flex(1 1 100%);
      // height: 346px;
      // height: 275px;
      position: absolute;
      // top: -500px;
      top: -200%;
      left: 0;
      width: 100%;
      transition: all 500ms cubic-bezier(0.79, 0.09, 0, 1);

      @include bp-max-1024 {
        @include flex-wrap(wrap);
        margin-top: 0;
        max-height: 60px;
        overflow: hidden;
        position: relative;
        top: 0;
        transition: all 500ms cubic-bezier(0.65, 0, 0.21, 1);

        &.mega__section-labels--is-open {
          // height: 400px;
          max-height: 350px;
        }
      }

      &.mega__content--active {
        top: 0;
      }
    }

    > .mega__section-labels {
      .mega__section-title {
        @include bp-max-1024 {
          @include font-size(3);
        }
      }
    }

    .mega__section-labels {
      @include flexbox;
      // @include flex-direction(column);
      @include flex-direction(row);
      margin: auto;
      padding: 20px 20px 20px 100px;
      text-align: right;
      width: 486px;
      // @include flex-wrap(wrap);
      @include flex-wrap(nowrap);
      @include flex(1 1 100%);
      // height: 100%;
      margin: 0;
      padding: 0;
      width: inherit;

      // @include bp-min-1920 {
      //   @include flexbox;
      //   @include align-items(flex-end);
      //   @include flex-wrap(wrap);
      //   @include flex(1 1 100%);
      //   // height: 346px;
      //   margin: 0;
      //   padding: 0;
      //   width: inherit;
      // }

      @include bp-max-1024 {
        background-color: white;
        // border-bottom: 1px solid $lightGray;
        margin-bottom: 0;
        @include flex(1 1 100%);
        @include flex-direction(row);
        @include flex-wrap(wrap);
        padding: 15px 0px 10px 20px;
        text-align: left;
        width: 100%;
      }

      @include bp-max-420 {
        padding: 15px 15px 5px;
        height: 308px;
      }

      &:last-of-type {
        @include bp-max-420 {
          border-bottom: none;
          @include align-items(flex-start);
          padding-bottom: 5px;
          padding-right: 0;
        }
      }

      &.mega__section-labels--mobile-is-visible {
        display: none;

        @include bp-max-1024 {
          display: block;
          height: initial;
        }
      }

      a {
        color: $gunmetal;
      }

      .mega__section-labels__label {
        @include flexbox;
        @include flex(0 1 30%);
        @include flex-wrap(wrap);
        @include align-content(center);

        @include bp-max-1024 {
          @include flex(1 1 100%);
        }

        .mega__section-title {
          color: $gunmetal;
          @include font-size(4.2);
          margin-bottom: 20px;
          @include flexbox;
          @include align-self(flex-end);
          @include align-items(flex-end);
          @include justify-content(flex-end);
          @include flex(0 1 100%);
          margin-right: 40px;
          text-align: right;
          // width: 100%;

          @include bp-min-1920 {
            // @include flexbox;
            // @include align-self(flex-end);
            // @include align-items(flex-end);
            // @include flex(0 1 45%);
            @include font-size(5);
            // margin-right: 40px;
            // text-align: right;
            // width: 16%;
          }

          @include bp-max-1200 {
            @include font-size(3.6);
            margin-right: 20px;
          }

          @include bp-max-1024 {
            @include flex(1 1 100%);
            @include align-items(flex-start);
            @include align-self(flex-start);
            @include justify-content(flex-start);
            @include font-size(3);
            margin-bottom: 0;
            text-align: left;
            width: 100%;
          }

          &.mega__section-title--active {
            color: $red;
          }
        }

        .mega__section-description {
          color: $gunmetal;
          @include font-size(2.4);
          @include align-self(flex-end);
          @include flex(0 1 100%);
          margin-right: 40px;
          padding-left: 20px;
          // width: 25%;

          // @include bp-min-1920 {
          //   @include align-self(flex-end);
          //   @include flex(0 1 auto);
          //   margin-right: 40px;
          //   width: 16%;
          // }

          @include bp-max-1200 {
            @include font-size(2);
            margin-right: 20px;
            width: 19%;
          }

          @include bp-max-1024 {
            display: none;
          }
        }
      }
    }

    .mega__section-cards {
      background-color: $white;
      @include flexbox;
      @include align-items(stretch);
      @include flex(1 1 70%);
      @include flex-wrap(wrap);
      // max-width: 73%;
      padding: 40px;
      text-align: left;

      @include bp-min-1920 {
        // width: 80%;
      }

      @include bp-max-1024 {
        cursor: grab;
        @include flex-wrap(nowrap);
        padding-top: 10px;
        padding-left: 20px;
        max-width: inherit;
        overflow-x: scroll;
        padding-right: 0;
      }

      @include bp-max-420 {
        padding-left: 15px;
      }

      .mega__section-card {
        border: 1px solid $lightGray;
        color: $gunmetal;
        margin-right: 20px;
        max-width: 285px;
        width: 33%;

        @include bp-min-1920 {
          max-width: 350px;
        }

        @include bp-1440 {
            max-width: 250px;
        }

        @include bp-max-1200 {
          @include flex(1 0 100%);
          max-width: 220px;
          width: 220px;
        }

        @include bp-max-420 {
          @include flex(1 0 100%);
          max-width: 220px;
          width: 220px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          .mega__page-image-mask {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

            .mega__page-image {
              transform: scale(1.25);
            }
          }
        }

        .mega__page-image-mask {
          overflow: hidden;
          transition: all 300ms;

          .mega__page-image {
            width: 100%;
            transition: all 500ms;
          }
        }

        .mega__page-title {
          @include font-size(2.4);
          padding: 10px 20px 10px;
        }

        .mega__page-description {
          @include font-size(1.6);
          padding: 0 20px 20px;
        }
      }
    }

    .mega__close {
      background-color: white;
      border-radius: 50%;
      box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.33);
      cursor: pointer;
      @include flexbox;
      height: 40px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 10px);
      padding: 10px;
      width: 40px;
      z-index: 1;
      transition: all 300ms;

      @include bp-max-1024 {
        display: none;
      }

      &:hover {
        background-color: $red;

        i.arrow {
          span {
            border: solid $white;
            border-width: 0 2px 2px 0;
          }
        }
      }

      .arrow {
        margin: auto;
        position: relative;

        .arrow--up {
          margin-left: 0;
          margin-top: 6px;
          padding: 6px;
        }
      }
    }
  }
}
