/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
header {
    @include bp-min-1921 {
        max-width: 1920px;
        left: calc(50% - 960px);
    }

    background-color: white;
    display: block;
    position: fixed;
    width: 100%;
    z-index: 10;

    &.sticky {
        box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.3);

        .tertiary-wrap {
            margin-top: -42px;

            @include bp-max-1200 {
                height: 36px;
                overflow: hidden;
            }
        }

        .primary-wrap {
            // margin: 10px 0;
            .logo {
                width: 175px;

                @include bp-max-420 {
                    width: 140px;
                }
            }
        }
    }

    .tertiary-wrap {
        background-color: $white;
        @include flexbox;
        padding-left: 20px;
        width: 100%;
        transition: margin 250ms;
        position: relative;
        z-index: 2;

        @include bp-min-1920 {
            padding-left: 80px;
        }

        @include bp-max-1024 {
            display: none;
        }

        .global {
            // @include flex(0 1 370px);
            margin-right: 58px;
            // min-width: 370px;
            padding: 10px 0;

            @include bp-max-1200 {
                margin-right: 15px;
                min-width: 340px;
            }
        }

        .tertiary {
            background-color: $lightGray;
            @include flexbox;
            @include justify-content(flex-end);
            @include flex(1 1 820px);
            padding: 10px 40px 10px;

            ul {
                li {
                    .txt-link {
                        margin-left: 7px;
                    }
                }
            }

            .lang {
                margin-left: 52px;

                @include bp-min-1920 {
                    margin-left: 30px;
                }
            }

            @include bp-min-1920 {
                padding: 10px 80px 10px;
            }

            @include bp-max-1200 {
                padding: 10px 15px 10px;
            }
        }

        .tertiary:before {
            content: "";
            border-top: 72px solid transparent;
            border-left: 20px solid white;
            border-bottom: 0px solid transparent;
            position: absolute;
            left: 0;
            top: -10px;
        }
    }

    .primary-wrap {
        background-color: $white;
        @include flexbox;
        @include align-items(center);
        padding: 20px 20px 27px;
        position: relative;
        width: 100%;
        z-index: 2;

        @include bp-min-1920 {
            padding: 20px 80px 27px;
        }

        @include bp-max-1200 {
            // padding-right: 15px;
        }

        @include bp-max-768 {
            padding-bottom: 20px;
        }

        @include bp-max-420 {
            padding-left: 15px;
            padding-right: 15px;
        }

        .logo {
            width: 225px;
            transition: width 250ms ease-in-out;

            @include bp-min-1920 {
                width: 231px;
            }

            @include bp-max-768 {
                width: 187px;
            }

            @include bp-max-420 {
                width: 140px;
            }
        }

        .primary {
            @include flexbox;
            @include justify-content(flex-end);
            @include align-items(center);
            @include flex(1 1 auto);
            position: relative;

            @include bp-max-1200 {
                margin-left: 15px;
            }

            li {
                @include bp-max-1024 {
                    &#login {
                        @include bp-max-1024 {
                            margin-right: 20px;
                        }

                        @include bp-max-420 {
                            margin-right: 10px;
                            margin-left: 10px;
                        }

                        .btn {
                            @include bp-max-420 {
                                padding: 10px;
                            }
                        }
                    }

                    &.mobile--not-visible {
                        display: none;
                    }
                }

                @include bp-min-1025 {
                    &.mobile--is-visible {
                        display: none;
                    }
                }

                a {
                    span {
                        @include bp-max-1200 {
                            // display: none;
                        }
                    }

                    .primary__menu-arrow-wrap {
                        display: inline-block;
                        height: 22px;
                        overflow: hidden;

                        svg {
                            transform: translateY(10px);
                            width: 12px;
                            height: 36px;
                            transition: transform 200ms linear 0s;

                            polygon {
                                fill: $gunmetal;
                            }
                        }

                        &.primary__menu-arrow-wrap--active svg {
                            transform: translateY(-14px);

                            polygon {
                                fill: $red;
                            }
                        }
                    }

                    &.mega__anchor {
                        &:hover {
                            polygon.icon {
                                fill: $red;
                            }
                        }
                    }
                }

                .lang {
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        @include bp-max-420 {
                            @include flexbox;
                        }

                        li {
                            display: inline;
                            margin-right: 8px;

                            &:last-of-type {
                                border-left: 1px solid $gunmetal;
                                padding-left: 12px;
                                margin-right: 20px;

                                @include bp-max-420 {
                                    margin-right: 10px;
                                    padding-left: 8px;
                                }
                            }

                            .txt-link {
                                margin: 0;

                                @include bp-max-420 {
                                    @include font-size(1.3);
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }

        .hamburger-wrap {
            cursor: pointer;
            @include flexbox;
            @include align-items(center);
            @include font-size(2);
            //height: 24px;
            //margin-left: 63px;
            &.is-open {
                z-index: 999;

                .hamburger {
                    .hamburger__line:nth-child(1) {
                        //background-color: $white;
                        transform: rotate(45deg) translateY(6px) translateX(6px);

                        @include bp-max-420 {
                            width: 24px;
                        }
                    }

                    .hamburger__line:nth-child(2) {
                        width: 0;
                    }

                    .hamburger__line:nth-child(3) {
                        //background-color: $white;
                        transform: rotate(-45deg) translateX(4px) translateY(-4px);
                        width: 32px;

                        @include bp-max-420 {
                            width: 24px;
                        }
                    }
                }
                //.txt-link {
                //color: $white;
                //}
                // &:hover {
                //   .hamburger__line:nth-child(1) {
                //     transform: rotate(45deg) translateY(2px) translateX(7px)
                //       scale(1.125);
                //     @include bp-max-420 {
                //       transform: rotate(45deg) translateY(6px) translateX(6px)
                //         scale(1.125);
                //     }
                //   }
                //   .hamburger__line:nth-child(2) {
                //     width: 0;
                //   }
                //   .hamburger__line:nth-child(3) {
                //     transform: rotate(-45deg) translateX(9px) translateY(-3px)
                //       scale(1.125);
                //     width: 32px;
                //     @include bp-max-420 {
                //       transform: rotate(-45deg) translateX(4px) translateY(-5px)
                //         scale(1.125);
                //       width: 20px;
                //     }
                //   }
                //   &:last-of-type {
                //     @include bp-max-420 {
                //       width: 20px;
                //     }
                //   }
                // }
            }

            > span:first-of-type {
                @include bp-max-420 {
                    display: none;
                }
            }

            .hamburger {
                //height: 20px;
                //margin-right: 10px;
                //width: 18px;
                margin-left: 12px;

                @include bp-max-420 {
                    margin-left: 0px;
                }

                .hamburger__line {
                    background-color: $gunmetal;
                    display: block;
                    height: 2px;
                    margin-bottom: 5px;
                    width: 32px;
                    transition: all 250ms ease-in-out;

                    @include bp-max-420 {
                        width: 20px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                        width: 24px;

                        @include bp-max-420 {
                            width: 15px;
                        }
                    }
                }
            }
            // &:hover {
            //   .hamburger__line:nth-child(1) {
            //     transform: translateY(-2px);
            //   }
            //   .hamburger__line:nth-child(2) {
            //     width: 0;
            //   }
            //   .hamburger__line:nth-child(3) {
            //     transform: translateY(2px);
            //   }
            // }
        }
    }
}

main {
  padding-top: 144px;
  margin-bottom: 100px;

  @include bp-min-1920 {
    background-color: $white;
    margin: 0 auto;
    max-width: 1920px;
    padding-bottom: 100px;
  }

  @include bp-max-1024 {
    padding-top: 80px;
  }

  @include bp-max-768 {
    margin-bottom: 50px;
  }
}

.has-quad-and-gray-bar {
  @include flexbox;
  @include justify-content(flex-start);
  @include flex-wrap(wrap);
  margin: 52px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  .has-quad-and-gray-bar__copy {
    @include flex(0 1 40%);
    margin-left: 106px;
    opacity: 1;
    transition: all 800ms cubic-bezier(0.15, 0.56, 0.35, 1);

    @include bp-min-1920 {
      margin-left: 136px;
    }
  }

  .has-quad-and-gray-bar__gray-bar {
    background-color: $lightGray;
    @include flex(1 1 100%);
    padding: 50px 50px 102px;
    margin-top: 70px;

    @include bp-min-1920 {
      padding: 50px 136px 102px;
    }
  }

  p + .has-quad__gray-bar__content {
    margin-top: 132px;
  }

  .has-quad-and-gray-bar__quad {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    //overflow: hidden;
    position: absolute;
    top: 0;
    height: 482px;
    width: calc(48% - 104px);
    max-width: 612px;
    transition: all 800ms cubic-bezier(0.15, 0.56, 0.35, 1);

    .has-quad__gray-bar__quad__image {
      perspective: 20px;
      width: 100%;
      > img {
        transition: transform 500ms;
      }
    }

    &.has-quad-and-gray-bar__video {
      max-width: inherit;
      z-index: 2;
    }

    &.has-quad-and-gray-bar__quad--perspective {
      perspective: 50px;

      &:after {
        content: "";
        position: absolute;
        top: 105%;
        left: 0;
        width: 100%;
        height: 60px;
        border-radius: 100%;
        background: radial-gradient(
          ellipse at center,
          rgba(155, 155, 155, 0.33) 0%,
          rgba(255, 255, 255, 0) 61%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .has-quad-and-gray-bar__quad--animate {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
        height: 100%;
        width: 100%;
        transition: transform 500ms;
      }
    }
  }

  .has-quad-and-gray-bar__quad--slant-left {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
    transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1.01);
    background-color: $red;

    &:hover {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      width: 82%;

      @include bp-min-1920 {
        width: 50%;
      }
    }
  }

  .has-quad-and-gray-bar__quad--slant-top {
    -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
    background-color: $red;
  }

  .has-quad__gray-bar__quad__video {
    @include flexbox;
    @include flex-direction(column);
    @include align-items(center);
    padding: 0 20%;
    text-align: center;

    p {
      color: $white;
      font-family: $font-main-semibold;
      font-weight: 700;
      width: 83%;
    }
  }
}

.has-quad.has-gray-bar.has-quad__quad--right.fly-in,
.has-quad__quad--right.fly-in {
  .has-quad__quad {
    opacity: 0;
    position: relative;
    right: -100%;
    left: unset;
    transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1);
  }
  .has-quad-and-gray-bar__copy {
    transform: translateX(-200px);
    transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1);
  }
  &.has-quad-and-gray-bar--animate {
    .has-quad-and-gray-bar__copy {
      transform: translateX(0);
    }
    .has-quad__quad {
      right: 0;
      opacity: 1;
    }
  }
}

.has-quad.has-gray-bar.has-quad__quad--left.fly-in,
.has-quad__quad--left.fly-in {
  .has-quad-and-gray-bar__copy {
    opacity: 0;
    transform: translateX(200px);
    transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1);
  }
  .has-quad__quad {
    opacity: 0;
    position: relative;
    right: unset;
    left: -100%;
    transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1);
  }
  &.has-quad-and-gray-bar--animate {
    .has-quad-and-gray-bar__copy {
      opacity: 1;
      transform: translateY(0);
    }
    .has-quad__quad {
      left: 0;
      opacity: 1;
    }
  }
  > .has-quad-and-gray-bar__copy:first-of-type {
    padding-left: 52px;
  }
  .has-quad__quad {
    left: -100%;
    right: unset;
    opacity: 0;
  }
  .has-quad__gray-bar__content {
    margin-left: 0;
    padding-left: 52px;
  }
  .has-quad-and-gray-bar__gray-bar {
    @include flexbox;
    @include justify-content(flex-end);
  }
  .has-quad__gray-bar__content.has-quad__gray-bar__content--scroller {
    margin-left: 0;
    padding-left: 52px;
    flex: none;
    width: 48%;
  }
}

.has-quad-and-gray-bar.has-quad-and-gray-bar__quad--left.has-quad-and-gray-bar__quad--scroller {
  @include justify-content(flex-end);
}

.full-width {
    .row-of-images {
        @include flexbox;

        @include bp-max-1024 {
            @include flex-wrap(wrap);
            @include justify-content(space-evenly);
        }

        .row-of-images__image {
            @include flexbox;
            @include justify-content(center);
            @include flex(1 1 100%);
            overflow: hidden;
            position: relative;
            text-align: center;

            @include bp-max-1024 {
                @include flex(1 1 48%);
                margin-bottom: 10px;
            }

            @include bp-max-420 {
                @include flex(1 1 100%);
                margin-bottom: 10px;
            }

            &:hover {
                > img {
                    transform: scale(1.1);
                }

                .gradient-overlay--black {
                    z-index: -1;
                }
            }
        
        > img {
            max-width: 100%;
            width: 100%;
            transition: all 400ms ease-in-out;
        }

        .gradient-overlay {
            &.gradient-overlay--black {
                @include gradientOverlay(0, 0.35, 0.85);
                height: 100%;
                opacity: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 1;
                transition: all 400ms ease-in-out;
            }
        }
            

        > span {
            color: $white;
            @include font-size(2.4);
            font-family: $font-main-semibold;
            position: absolute;
            top: calc(100% - 100px);
            left: 0;
            right: 0;
            text-transform: uppercase;
            z-index: 1;
        }
        }
    }

    h2.text--center + .row-of-images {
        margin-top: 40px;
    }
}

.not-full-width {
    margin: 0 106px;

    @include bp-max-420 {
        margin-left: 15px;
        margin-right: 15px;
        padding: 0 30px;
    }

    &.no-hero {
        @include bp-max-420 {
            margin: 40px 20px 0px;
            padding: 0px;
        }

        .not-full-width__text-block {
            margin-top: 100px;

            h2 + .not-full-width {
                margin-top: 40px;

                @include bp-max-420 {
                    margin: 40px 0px 0px;
                    padding: 0px;
                }
            }
        }
    }

    &.not-full-width--has-tabs {
        @include bp-max-420 {
            margin: 0;
        }

        .on-page-tabs-wrapper {
            .on-page-tabs__tabs {
                @include bp-max-420 {
                    margin: 0;
                }
            }

            .on-page-tabs__contents {
                @include bp-max-420 {
                    padding: 0px 20px;
                }
            }
        }
    }

    .tabbed-content__tabs + & {
        > p {
            @include font-size(2.2);
        }
    }

    .tabbed-content--with-big-btn + & {
        margin-top: 200px;

        @include bp-max-420 {
            margin: 100px 0px 0px;
            padding: 0px 15px;
        }
    }

    .tabbed-content--with-gray-bg + & {
        margin-top: 100px;

        @include bp-max-420 {
            margin: 50px 0px 0px;
            padding: 0px 15px;
        }
    }

    .tabbed-content--with-big-btn.has-parallax + & {
        @include bp-max-1024 {
            margin-top: 100px;
        }

        @include bp-max-768 {
            margin-top: 50px;
        }
    }

    h2 + .list.list--inline {
        margin-top: 50px;
    }

    .full-width.has-gray-bg + & {
        margin-top: 175px;

        @include bp-max-768 {
            margin: 0 60px;
            margin-top: 100px;
        }

        @include bp-max-420 {
            margin: 0;
            margin-top: 100px;
        }
    }

    @include bp-max-768 {
        margin: 0 40px;
    }

    &.not-full-width--hp {
        @include bp-min-1920 {
            margin: 0 135px;
        }

        @include bp-max-1024 {
            margin: 0 106px;
        }

        @include bp-max-768 {
            margin: 0 40px;
        }

        @include bp-max-420 {
            // margin: 0 15px;
            margin: 0;
            margin-bottom: 25px;
            padding: 0 30px;
        }

        .col2-eq {
            @include bp-max-420 {
                margin: 0;
                margin-bottom: 25px;
            }
        }
    }

    .col2-eq + & {
        @include bp-min-1920 {
            margin: 0 230px;
        }
    }

    .col2-eq {
        .col2-eq__l {
            padding-left: 60px;
            padding-right: 40px;

            &.border-right {
                border-right: 1px solid $lightGray;

                @include bp-max-1024 {
                    padding-left: 0;
                }

                @include bp-max-768 {
                    margin-left: 40px;
                }

                @include bp-max-420 {
                    border-right: none;
                    // border-top: 1px solid $gunmetal;
                    // margin-top: 50px;
                    margin-top: 0;
                    margin-left: 0;
                    padding: 0;
                    padding-top: 25px;
                }
            }
        }

        .col2-eq__r {
            margin-left: 40px;
            padding-right: 60px;

            @include bp-max-1024 {
                padding-right: 0;
            }

            @include bp-max-768 {
                margin-right: 0;
                padding-right: 40px;
            }

            @include bp-max-420 {
                // margin-left: 15px;
                // margin-right: 15px;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    .col1 {
        @include flexbox;
        @include flex-direction(column);
        width: 50%;

        &.col1--centered {
            margin: 0 auto;
        }

        @include bp-max-420 {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .h2.text--center + .col1.col1--centered {
        margin-top: 60px;
    }
}

.wrap--border-box {
  border: 2px solid $lightGray;
  margin: 40px 0 70px;
  padding: 36px;

  @include bp-max-768 {
    padding: 36px 20px;
  }

  @include bp-max-420 {
    margin-bottom: 40px;
  }

  h1:first-of-type {
    margin-top: 0;
  }
}

.list.list--inline {
  @include flexbox;
  list-style: none;
  margin: 0;
  padding: 0;

  &.list--with-img {
    @include justify-content(center);

    @include bp-max-768 {
      @include justify-content(space-between);
    }

    @include bp-max-420 {
      @include flex-wrap(wrap);
      @include justify-content(flex-start);
    }

    li {
      a {
        @include flexbox;
        @include align-items(center);

        img {
          margin-right: 10px;

          @include bp-max-768 {
            margin-right: 5px;
          }

          @include bp-max-420 {
            margin-right: 20px;
          }
        }
      }
    }

    a.list--inline__card {
        color: $red;
    }

    .list--inline__card {
      border: 1px solid $lightGray;
      margin-right: 20px;
      overflow: hidden;

      @include bp-max-420 {
        @include flex(1 1 100%);
        margin-right: 0;
        margin-bottom: 20px;
      }

      &:last-of-type {
        margin-right: 0px;
      }

      &:hover {
        .card__page-image-mask {
          transform: scale(1.1);
        }
      }

      .card__page-image-mask {
        transition: all 500ms;
      }

      .card__page-title {
        padding: 20px 20px 0px;
      }

      .card__page-description {
        padding: 0px 20px 20px;
      }
    }
  }

  &.list--with-links {
    @include justify-content(space-between);

    @include bp-max-1024 {
      @include flex-wrap(wrap);
      @include justify-content(flex-start);
    }

    @include bp-max-420 {
      padding: 0px 15px;
    }

    > div:first-of-type {
      @include bp-max-1024 {
        @include flex(1 1 100%);
        margin-bottom: 10px;
      }
    }

    .list--inline__link {
      @include bp-max-1024 {
        margin-right: 20px;
      }

      @include bp-max-420 {
        margin-right: 10px;
      }
    }
  }

  li {
    display: inline;
    margin-right: 40px;

    @include bp-max-768 {
      margin-right: 15px;
    }

    @include bp-max-420 {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  li:last-of-type {
    margin-right: 0;
  }
}

h1 + .items.carousel,
h2 + .items.carousel {
  margin-top: 27px;

  @include bp-max-768 {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 70px);
  }

  @include bp-max-420 {
    padding: 0 20px;
    width: 100%;
    // width: calc(100% - 80px);
  }
}


footer {
  background-color: $lightGray;
  @include flexbox;
  @include flex-wrap(wrap);
  padding: 52px 80px 40px;

  @include bp-min-1920 {
    margin: 0 auto;
    max-width: 1920px;
    padding: 52px 75px;
  }

  @include bp-max-768 {
    padding: 52px 20px;
  }

  @include bp-max-420 {
    padding: 52px 20px 5px;
  }

  .footer__links {
    @include flexbox;
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    @include flex(1 1 100%);

    @include bp-max-1024 {
        @include justify-content(flex-start);
    }

    @include bp-max-768 {
      // @include justify-content(flex-start);
      // @include flex-direction(column);
      // height: 290px;
      height: inherit;
    }

    @include bp-max-420 {
      // height: 440px;
    }

    section {
      @include flex(0 1 auto);
      margin-right: 0;
      max-width: 147px;

      @include bp-min-1920 {
        max-width: 185px;
        margin-right: 0;
      }

      @include bp-max-1024 {
        margin-right: 20px;
        // max-width: 147px;
        // margin-bottom: 20px;
        @include flex(0 0 195px);
        max-width: 195px;
        margin-bottom: 40px;
      }

      @include bp-max-768 {
        // margin-right: 20px;
        // max-width: 147px;
        // margin-bottom: 20px;
        @include flex(0 0 161px);
        max-width: 161px;
        // margin-bottom: 40px;
      }

      @include bp-max-420 {
        // margin-right: 20px;
        // max-width: 147px;
        // margin-bottom: 20px;
        @include flex(0 0 139px);
        max-width: 139px;
        // margin-bottom: 40px;
      }

      &:nth-child(1) {
        @include bp-max-768 {
          @include order(1);
        }

        @include bp-max-420 {
          // @include flex(0 1 auto);
        }
      }

      &:nth-child(2) {
        @include bp-max-768 {
          @include order(2);
        }
      }

      &:nth-child(3) {
        @include bp-max-768 {
          @include order(3);
        }
      }

      &:nth-child(4) {
        @include bp-max-768 {
          @include order(4);
        }

        @include bp-max-420 {
          @include order(7);
        }
      }

      &:nth-child(5) {
        @include bp-max-768 {
          @include order(5);
        }
      }

      &:nth-child(6) {
        @include bp-max-768 {
          @include order(6);
        }
        @include bp-max-420 {
          @include flex(0 1 auto);
        }

        a {
            @include bp-max-768 {
                overflow-wrap: break-word;
            }
        }
      }

      &.footer__social {
        max-width: 150px;

        @include bp-max-768 {
          // @include order(7);
          // margin-left: 43px;
          @include order(3);
          margin-left: 0;
        }
        @include bp-max-420 {
          @include flex(0 1 auto);
          @include order(7);
          margin-left: 0;
        }

        h2 {
          margin-bottom: 20px;
        }

        @include bp-min-1920 {
          text-align: right;
        }

        .list--with-img {
          li {
            margin-right: 0;
            @include bp-max-768 {
              margin-right: 15px;
            }

            @include bp-max-420 {
              margin-right: 0px;
            }

            img {
              max-width: 50px;

              @include bp-min-1920 {
                max-width: 85px;
              }
            }
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
      }

      h2 + ul {
        margin-top: 5px;
      }
    }

    section:last-of-type {
      margin-right: 0;
    }
  }

  .legal {
    @include flex(1 1 100%);
    margin: 70px 0 0;

    @include bp-min-1920 {
      margin: 52px 0 0;
    }

    @include bp-max-1024 {
      margin: 20px 0 62px;
    }

    @include bp-max-420 {
      @include flex-wrap(wrap);
    }

    .list.list--inline {
      @include flex(1 1 40%);
      @include align-items(flex-start);

      @include bp-max-768 {
        @include flex(0 1 40%);
      }

      @include bp-max-420 {
        @include flex(1 1 100%);
      }

      li {
        margin-right: 5px;
        padding-right: 10px;

        @include bp-max-768 {
          display: block;
        }
      }

      li:last-of-type {
        margin-right: 0;
      }
    }

    p {
      @include flex(1 1 60%);

      @include bp-min-1920 {
        max-width: 750px;
      }

      @include bp-max-768 {
        @include flex(0 1 54%);
      }

      @include bp-max-420 {
        @include flex(1 1 100%);
        margin-top: 20px;
        text-align: left;
      }
    }
  }

  .footer__sticky {
    background-color: $gray;
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @include bp-max-1024 {
      @include flexbox;
      @include align-items(center);
    }

    a {
      display: block;
      height: 57px;
      position: relative;
      text-indent: -9999px;
      width: 57px;

      svg,
      img {
        position: absolute;
        top: calc(50% - 17.5px);
        left: calc(50% - 17.5px);
        width: 35px;
      }
    }

    a:last-of-type {
      margin-left: auto;
      margin-right: 20px;
    }
  }
}

.col2-eq {
    @include flexbox;
    margin: 100px 0;
    padding: 0;
    position: relative;
    width: 100%;

    @include bp-max-1024 {
        margin: 60px 0;
    }

    @include bp-max-420 {
        @include flex-wrap(wrap);
        margin: 50px 0;
    }

    &.col2-eq--no-bottom-margin {
        margin-bottom: 0;

        &.col2-eq {
            .col2-eq__l {
                &.has-quad__quad {
                    @include bp-min-1366 {
                        @include align-self(flex-end);
                    }
                }
            }
        }
    }

    &.fly-in {
        overflow: hidden;
    }

    &.has-gray-bar {
        padding-bottom: 40px;

        @include bp-max-768 {
            padding-bottom: 30px;
        }

        h1.mobile--is-visible {
            @include bp-max-420 {
                margin: 0 auto 20px;
            }
        }
    }

    &.has-gray-bar.has-quad__quad--right.has-quad__quad--scroller {
        padding-bottom: 0;

        @include bp-max-1024 {
            padding-bottom: 40px;
        }

        @include bp-max-768 {
            padding-bottom: 0;
        }

        @include bp-max-420 {
            padding-bottom: 40px;
        }

        &.has-quad__quad--scroller-fw {
            padding-bottom: 40px;

            @include bp-max-768 {
                padding-bottom: 20px;
            }

            @include bp-max-420 {
                padding-bottom: 40px;
            }
        }
    }

    &#hp-section-2 {
        @include bp-max-420 {
            padding-bottom: 0;
        }
    }

    .has-quad__copy {
        margin-bottom: 80px;

        @include bp-max-768 {
            margin-bottom: 20px;
        }

        @include bp-max-420 {
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 10px;
        }
    }

    &.has-quad__quad--scroller.has-quad__quad--scroller-fw {
        .col2-eq__r.has-quad__quad {
            @include bp-max-1024 {
                margin-right: 0;
                position: absolute;
                right: 40px;
                width: 45%;
            }

            @include bp-max-420 {
                margin-right: 15px;
                position: relative;
                right: 0;
                width: 100%;
            }
        }

        .col2-eq__l {
            @include bp-max-1024 {
                width: 92%;
            }

            @include bp-max-420 {
                margin-top: 0;
                margin-left: 0;
                width: 100%;
            }

            .has-quad__copy {
                @include bp-max-1024 {
                    width: 46%;
                }

                @include bp-max-420 {
                    padding: 0 15px;
                    width: 92%;
                }
            }
        }
    }

    &.has-quad__quad--right.fly-in.has-quad-and-gray-bar--animate.has-quad__quad--scroller.has-quad__quad--scroller-fw {
        .col2-eq__r.has-quad__quad {
            @include bp-max-1024 {
                right: 40px;
            }

            @include bp-max-420 {
                right: 0;
            }
        }
    }

    .has-quad__copy + .btns-inline-w-heading {
        // margin-top: 120px;
        margin-top: auto;

        @include bp-max-768 {
            margin-top: 40px;
        }
    }

    .has-quad__copy.has-quad-and-gray-bar__copy + .has-quad__carousel {
        margin-bottom: 0;
    }

    .has-quad__copy + .has-quad__carousel {
        margin: 80px 0 50px;
        margin-top: auto;

        @include bp-min-1920 {
            // margin: 90px 0;
        }

        @include bp-max-768 {
            // margin-top: 60px;
        }

        @include bp-max-420 {
            margin: 60px auto 0;
            padding: 0 15px;
            overflow: hidden;
            width: 100%;
        }

        > h3 {
            @include bp-max-420 {
                margin-left: 15px;
            }
        }
    }

    &.our-presence {
        .col2-eq__l,
        .col2-eq__r {
            > div {
                margin-top: 40px;

                &:first-of-type {
                    @include bp-max-420 {
                        margin-top: 0;
                    }
                }

                span.h1 {
                    display: block;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .col2-eq__l {
        @include flexbox;
        @include flex-direction(column);
        margin-left: 75px;
        padding-right: 40px;
        width: 50%;

        @include bp-min-1920 {
            margin-left: 136px;
        }

        @include bp-max-768 {
            margin-left: 40px;
            padding-right: 30px;
        }

        @include bp-max-420 {
            @include order(2);
            @include flex(1 1 100%);
            margin-left: 15px;
            margin-top: 20px;
            padding-right: 15px;
        }

        &.has-quad__quad {
            @include bp-max-420 {
                @include order(1);
                margin-top: 0;
            }

            + .col2-eq__r {
                @include bp-max-420 {
                    padding-top: 15px;
                }
            }
        }

        .has-quad__copy--entertainment-banking {
            width: 75%;

            @include bp-max-1200 {
                width: 100%;
            }
        }
    }

    .col2-eq__r {
        @include flexbox;
        @include flex-direction(column);
        margin-right: 75px;
        margin-left: 20px;
        @include order(2);
        width: 50%;

        @include bp-min-1920 {
            margin-right: 136px;
        }

        @include bp-max-768 {
            margin-right: 40px;
            margin-left: 10px;
        }

        @include bp-max-420 {
            @include flex(1 1 100%);
            margin-right: 15px;
            margin-left: 15px;
            padding: 30px 0;
        }

        &.has-quad__quad {
            @include bp-max-420 {
                @include order(1);
                padding-top: 0;
                padding-bottom: 15px;
            }
        }
    }

    .has-quad__video {
        height: 414px;

        @include bp-min-1920 {
            height: 482px;
        }

        @include bp-max-1024 {
            height: inherit;
        }

        &.is-animated {
            position: relative;
            right: -100%;
        }

        &.is-animated--animate {
            right: 0;
        }
    }

    &.col2-eq--text-next-to-image {
        margin: 50px 0px;
        @include align-items(center);

        @include bp-max-768 {
            @include flex-wrap(wrap);
            margin-top: 20px;
            padding: 0 30px;
        }

        .col2-eq__text {
            margin-left: 75px;
            padding-right: 100px;
            width: 45%;

            @include bp-max-768 {
                margin: 0px 0px 30px 0px;
                padding: 0;
                width: 100%;
            }
        }

        .col2-eq__image {
            @include bp-max-768 {
                width: 100%;
                max-width: 555px;
            }

            @include bp-max-420 {
                width: 100%;
            }
        }
    }

    &.col2-eq--fw {
        .col2-eq__r.has-quad__quad,
        .col2-eq__l.has-quad__quad {
            &.has-quad__quad--slant-left {
                -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);

                @include bp-max-420 {
                    -webkit-clip-path: none;
                    clip-path: none;
                }
            }

            &.has-quad__quad--slant-right {
                -webkit-clip-path: polygon(0 0, 80% 0%, 90% 100%, 0% 100%);
                clip-path: polygon(0 0, 80% 0%, 90% 100%, 0% 100%);

                @include bp-max-768 {
                    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
                    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
                }

                @include bp-max-420 {
                    -webkit-clip-path: none;
                    clip-path: none;
                }
            }
        }
    }
}

.has-gray-bar {
    &.has-quad {
        .btns-inline.btns-inline-w-heading {
            @include bp-max-1024 {
                & + .has-gray-bar__bar {
                    height: 225px;
                }
            }

            @include bp-max-768 {
                & + .has-gray-bar__bar {
                    height: 250px;
                }
            }

            @include bp-max-420 {
                display: block;
                margin-right: 0;
                margin-left: 15px;

                .btn {
                    @include bp-max-420 {
                        text-align: center;
                        width: 66%;
                    }
                }
            }
        }

        .has-quad__quad {
            &.has-quad__quad--perspective {
                position: relative;
                transition: all 500ms;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -40px;
                    left: 0;
                    width: 100%;
                    height: 60px;
                    border-radius: 100%;
                    background: radial-gradient( ellipse at center, rgba(155, 155, 155, 0.33) 0%, rgba(255, 255, 255, 0) 61%, rgba(255, 255, 255, 0) 100% );

                    @include bp-max-420 {
                        content: none;
                    }
                }
            }

            .has-quad__quad--slant-bottom {
                // -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
                // clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
                //height: 100%;
                width: 100%;
                transition: transform 500ms;

                .has-quad__image {
                    perspective: 20px;

                    > img {
                        width: 100%;
                        transition: transform 500ms;
                    }
                }
            }

            .has-quad__quad--slant-right {
                clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%);
                //height: 100%;
                width: 100%;
                transition: transform 500ms;

                .has-quad__image {
                    perspective: 20px;

                    > img {
                        width: 100%;
                        transition: transform 500ms;
                    }
                }
            }
        }

        .has-quad__video {
            @include flexbox;
            @include justify-content(center);
            // @include align-items(center);
            overflow: hidden;
            padding-right: 0;
            transition: all 500ms;

            @include bp-max-420 {
                padding: 30px 0;
            }

            &.has-quad__video--is-playing {
                background: $black;
                -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                width: 82%;

                @include bp-min-1920 {
                    width: 70%;
                }

                @include bp-max-1024 {
                    margin-right: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 2;
                }

                @include bp-max-420 {
                    position: relative;
                }

                video {
                    opacity: 1;
                }

                .vid__close {
                    display: block;
                    top: 20px;
                    right: 20px;
                    left: inherit;
                    z-index: 1;
                }

                .has-quad__video__labels {
                    // left: -100%;
                    opacity: 0;
                }
            }

            .vid__close {
                display: none;
            }

            video {
                max-height: 430px;
                opacity: 0;
                width: 100%;
                transition: all 500ms;

                @include bp-max-1024 {
                    max-height: inherit;
                    width: 100%;
                }
            }

            .has-quad__video__labels {
                @include flexbox;
                @include flex-direction(column);
                @include align-items(center);
                opacity: 1;
                padding: 0 20px;
                position: absolute;
                left: calc(50% - 301px);
                top: calc(50% - 86px);
                text-align: center;
                transition: opacity 300ms cubic-bezier(0.56, 0.01, 0.35, 1);

                @include bp-max-1024 {
                    left: 0;
                    width: 100%;
                }

                @include bp-max-420 {
                    top: calc(50% - 74px);
                }

                p {
                    color: #fff;
                    font-family: "Whitney-Semibold", Arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
                    font-weight: 700;
                    width: 60%;

                    @include bp-max-420 {
                        @include font-size(1.6);
                        width: 100%;
                    }
                }

                .btn-video-play + p {
                    margin-top: 20px;
                    @include font-size(1.8);
                }
            }

            &.has-quad__quad--slant-left {
                transition: all 1000ms cubic-bezier(0.54, -0.23, 0.14, 1.01);

                &:hover {
                    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    width: 82%;

                    @include bp-min-1920 {
                        width: 70%;
                    }

                    &.has-quad__video--is-playing {
                        @include bp-max-1024 {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .has-gray-bar__bar {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $lightGray;
            height: 200px;
            z-index: -1;

            @include bp-min-1920 {
                height: 200px;
            }

            @include bp-max-768 {
                height: 250px;
            }

            @include bp-max-1024 {
                height: 180px;
            }

            @include bp-max-420 {
                height: 248px;
            }

            &.has-gray-bar--450h {
                height: 370px;

                @include bp-min-1920 {
                    height: 390px;
                }

                @include bp-max-768 {
                    height: 335px;
                }

                @include bp-max-420 {
                    height: 330px;
                }
            }

            &.has-gray-bar--346h {
                height: 346px;

                @include bp-min-1920 {
                    height: 390px;
                }

                @include bp-max-1024 {
                    height: 365px;
                }

                @include bp-max-420 {
                    height: 330px;
                }
            }

            &.has-gray-bar__bar--blue {
                background: #3f51b5; /* Old browsers */
                background: -moz-radial-gradient(center, ellipse cover, #3f51b5 0%, #25338b 72%, #1f2c7d 100%); /* FF3.6-15 */
                background: -webkit-radial-gradient(center, ellipse cover, #3f51b5 0%,#25338b 72%,#1f2c7d 100%); /* Chrome10-25,Safari5.1-6 */
                background: radial-gradient(ellipse at center, #3f51b5 0%,#25338b 72%,#1f2c7d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f51b5', endColorstr='#1f2c7d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            }

            &.has-gray-bar__bar--has-carousel-with-labels {
                height: 425px;

                @include bp-min-1920 {
                    height: 400px;
                }

                @include bp-max-768 {
                    height: 400px;
                }

                @include bp-max-420 {
                    height: 365px;
                }
            }
        }

        &#hp-section-2.col2-eq {
            .has-gray-bar__bar {
                @include bp-max-768 {
                    height: 167px;
                    bottom: -20px;
                }

                @include bp-max-420 {
                    height: 145px;
                    bottom: 0px;
                }
            }
        }
    }
}

.has-quad__image {
  img {
    width: 100%;
  }
}

#top-control {
  background-color: black;
  border-radius: 100%;
  cursor: pointer;
  display: none;
  height: 50px;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  z-index: 9;

  @include bp-max-1200 {
    display: none;
  }

  &:hover {
    svg {
      transform: translateY(-5px);
    }
  }

  svg {
    transition: all 300ms;
  }
}

#top-control--mobile {
    border: 2px solid $gunmetal;
    border-radius: 100%;
    cursor: pointer;
    height: 35px;
    margin-right: 20px;
    padding: 7px;
    width: 35px;

    > svg {
        > polygon {
            fill: $gunmetal;
        }
    }
}

.has-gray-bg {
  > :nth-child(1) {


    @include bp-max-420 {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
    }
  }

  &.full-width {
      > h2:first-child {
          padding-top: 40px;
      }
  }
}

.cta-box {
    &.cta-box--art-program {
        @include backImage( "../img/about-art-program-view-more-gallery-bg.jpg", no-repeat, left top, cover, 550px, 0.75 );
        color: $white;
        padding: 200px 50px;
        text-align: center;

        @include bp-max-420 {
            padding: 100px 50px;
        }

        h2 {
            color: $white;
            @include font-size(4.8);
            font-family: $font-main-medium;

            @include bp-max-420 {
                @include font-size(3);
            }
        }

        p {
            color: $white;
            @include font-size(3);
        }

        .btn--big {
            @include font-size(3.6);

            &:last-child {
                margin-top: 30px;
            }
        }
    }
}

.cross-link-wrap {
    position: relative;
    z-index: 1;
}

.overlay {
    background-color: rgba(0,0,0,.5);
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 9;

    .overlay__inner {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        height: 100%;

        .overlay__inner__content {
            background-color: $white;
            box-shadow: 2px 2px 6px rgba(0,0,0,.7);
            max-width: 360px;
            text-align: center;
            padding: 50px;
            position: relative;

            .btn-circ-white-bg {
                top: 20px;
                right: 20px;
                left: inherit;

                > svg {
                    width: 20px;
                }
            }

            > p:first-of-type {
                margin-bottom: 10px;
            }
        }
    }
}

.error-page {
    height: 25vh;
    margin: 100px 0px 0px;
    padding: 20px;
    text-align: center;
}