﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />
.tabbed-content__content {

    .on-page-tabs-wrapper {
        margin-top: 40px;

        .on-page-tabs__tabs {
            background-color: $lightGray;
            @include flexbox;
            @include justify-content(space-evenly);
            padding: 10px 10px 0px;
            margin: 0 -15px;
            margin-bottom: 40px;

            .on-page-tabs__tab {
                cursor: pointer;
                @include flex(1 1 50%);
                @include font-size(2.4);
                @include flexbox;
                @include flex-direction(column);
                @include justify-content(center);
                font-family: $font-main-semibold;
                padding: 25px 20px;
                text-align: center;
                text-transform: uppercase;

                @include bp-max-768 {
                    @include font-size(2);
                }

                @include bp-max-420 {
                    @include font-size(1.4);
                }

                &:after {
                    background-color: $red;
                    content: "";
                    height: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    transition: all 600ms;
                }

                &.on-page-tabs__tab--active {
                    background-color: white;
                    color: $red;
                    position: relative;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .on-page-tabs__contents {

        .on-page-tabs__contents__content {
            &.on-page-tabs__contents__content--not-visible {
                display: none;
            }

            ul {
                background-color: transparent;
                color: inherit;
                list-style: disc;
                margin: 0px 40px;

                li {
                    color: $gunmetal;
                    margin-bottom: 20px;

                    @include bp-min-1920 {
                        @include font-size(2);
                    }
                }
            }

            .on-page-tabs__contents__list {
                @include flexbox;
                border-bottom: 1px solid $lightGray;
                margin-bottom: 20px;
                padding-bottom: 12px;

                > p:first-of-type {
                    @include flex(0 0 25%);
                    margin-right: 10px;
                }

                &:first-of-type {
                    margin-top: 20px;
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }
}
