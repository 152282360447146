/// <reference path="_variables.scss" />
/// <reference path="_mixins.scss" />
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

.test {
    cursor: pointer;
}

body {
  background-color: $white;
  color: $darkGray;
  font-family: $font-main-book;
  @include font-size(1.8);
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @include bp-min-1920 {
      background-color: #f4f4f4;
  }

  &.zh {
    font-family: $font-zh;

    h1 {
      @include font-size(1.8);
      font-family: $font-zh;

      &.zh--larger {
        @include font-size(2.4);
      }
    }

    div > h1:first-of-type {
      margin: 0px 0 20px;
    }

    h2,
    span.h2 {
      @include bp-max-420 {
        @include font-size(3);
      }

      &.font--medium {
        font-family: $font-zh;
      }
    }

    h1.mobile--not-visible + h2 {
      @include bp-max-420 {
        margin-top: 0px;
      }
    }

    h2 strong,
    span.h2 strong {
      font-family: $font-zh;
    }

    h1 + h2 {
      margin-top: 10px;

      @include bp-max-768 {
        margin-top: 20px;
      }
    }

    //Any <p> after an h2
    h2 + p {
      margin-top: 10px;

      @include bp-max-768 {
        margin-top: 30px;
      }

      @include bp-max-420 {
        margin-top: 10px;
      }
    }

    h3 {
      font-family: $font-zh;
      font-weight: normal;

      @include bp-max-1024 {
        @include font-size(2.2);
      }

      @include bp-max-420 {
        @include font-size(2);
      }
    }

    h4 {
      font-family: $font-zh;
    }

    p + .btn {
      @include bp-max-768 {
        margin-top: 30px;
      }
    }

    strong {
        font-family: $font-zh;
    }

    .btn {
      font-family: $font-zh;

      &.btns-row {
        @include font-size(1.8);

        @include bp-min-1920 {
          @include font-size(2);
        }

        @include bp-max-768 {
          display: block;
        }

        @include bp-max-420 {
          @include font-size(1.8);
        }
      }

      &.btn--big {
        @include font-size(4.2);
        max-width: 750px;

        @include bp-max-1024 {
          max-width: initial;
          margin-top: 0;
        }

        @include bp-max-420 {
          @include font-size(2.4);
          max-width: 90%;
          // top: 50px;
        }

        &.btn--big--has-icon {
          max-width: 990px;

          .btn--big--has-icon__label {
              @include font-size(5);

              @include bp-max-768 {
                  @include font-size(3.5);
              }

              @include bp-max-420 {
                  @include font-size(2.5);
              }
          }
        }

        &:last-child {
            margin-top: 40px;
        }
      }
    }

    .txt-link {
      font-family: $font-zh;
      @include font-size(2);

      @include bp-min-1920 {
        @include font-size(2.4);
      }

      @include bp-max-768 {
        @include font-size(1.8);
      }

      &.txt-link--active {
        font-family: $font-zh;
        font-weight: bold;
      }
    }

    header {
      .tertiary-wrap {
        ul {
          li {
            .txt-link {
              @include font-size(1.8);
              font-family: $font-zh;

              &.whitney-book {
                font-family: $font-main-book;
              }
            }
          }
        }
      }

      .primary-wrap {
        .primary {
          li {
            .txt-link {
              @include font-size(2);

              @include bp-min-1920 {
                @include font-size(2.4);
              }
            }
          }
        }
      }
    }

    .hero-wrap {
      .slider {
        .slider__slide {
          .slider__slide__content {
            span.slide__heading {
              font-family: $font-zh;
              @include font-size(4.8);

              @include bp-min-1920 {
                @include font-size(6);
              }

              @include bp-max-768 {
                @include font-size(3.6);
              }

              @include bp-max-420 {
                @include font-size(3);
              }
            }
            .slide__copy {
              p {
                @include font-size(2);
                line-height: 1.5em;

                @include bp-min-1920 {
                  @include font-size(2.4);
                  line-height: 1.5em;
                }

                @include bp-max-768 {
                  @include font-size(1.8);
                  line-height: 1.33em;
                }
              }
            }
          }
        }
      }

      &.subpage-hero {
        .subpage-hero__title {
          font-family: $font-zh;
          @include font-size(4.8);

          @include bp-max-768 {
            @include font-size(4);
          }

          @include bp-max-420 {
            @include font-size(3);
          }
        }
      }
    }

    .has-quad__copy p {
      @include font-size(2);
      line-height: 1.5em;

      @include bp-min-1920 {
        @include font-size(2.4);
      }

      @include bp-max-768 {
        @include font-size(1.8);
        line-height: 1.33em;
      }
    }

    .has-gray-bar.has-quad {
      .has-quad__video {
        &.has-quad__video--is-playing {
          .has-quad__video__labels {
            left: -100%;
          }
        }
        .has-quad__video__labels {
          left: calc(50% - 165px);

          @include bp-max-768 {
            left: calc(50% - 165px);
          }

          @include bp-max-420 {
            left: calc(50% - 165px);
            top: calc(50% - 54px);
          }

          p {
            font-family: $font-zh;
            @include font-size(2.4);
            font-weight: normal;
            width: initial;

            @include bp-max-768 {
              @include font-size(1.8);
            }
          }
        }
      }
    }

    .col2-eq {
      .has-quad__copy + .has-quad__carousel {
        margin-top: 110px;
        margin-top: auto;

        @include bp-max-420 {
          margin-top: 60px;
        }
      }

      .has-quad__copy {
        @include bp-max-420 {
          padding-right: 15px;
        }
      }
    }

    #hp-section-3 {
      .has-quad__carousel {
        h3 {
          @include bp-max-1024 {
            width: 50%;
          }

          @include bp-max-420 {
            width: 92%;
          }
        }
      }
    }

    .wrap--border-box {
      h1 {
        @include font-size(2.4);
      }
    }

    .carousel.news {
      .item {
        > p {
          @include bp-max-420 {
            @include font-size(1.8);
          }
        }
      }
    }

    .has-gray-bar {
      .has-gray-bar__bar {
        &.has-gray-bar--450h {
          @include bp-max-1024 {
            height: 380px;
          }

          @include bp-max-768 {
            height: 390px;
          }

          @include bp-max-420 {
            height: 360px;
          }
        }
      }
    }

    footer {
      .footer__links {
        @include bp-max-768 {
          height: 250px;
        }

        @include bp-max-420 {
          height: initial;
          @include flex-direction(row);
          @include justify-content(space-between);
        }

        section {
          &.footer__social {
            @include bp-max-420 {
              margin-left: 0;
            }
          }
        }
      }

      h2 {
          @include bp-max-420 {
              @include font-size(2);
          }

        .txt-link {
          @include font-size(1.6);
          font-weight: bold;

          @include bp-min-1920 {
            @include font-size(1.8);
          }
        }
      }
      ul {
        li {
          .txt-link {
            @include font-size(1.6);

            @include bp-min-1920 {
              @include font-size(1.8);
            }
          }
        }
      }
    }
    .tabbed-content {
      h2 {
        font-family: $font-zh;

        @include bp-max-420 {
          @include font-size(2.4);
          text-align: center;
        }
      }
      p {
        font-family: $font-zh;
      }
      ul.tabbed-content__tabs {
        li {
          font-family: $font-zh;
          @include flex(1 1 auto);

          @include bp-max-1024 {
            padding: 20px;
          }

          @include bp-max-768 {
            @include flex(1 0 360px);
          }

          @include bp-max-420 {
            @include flex(1 0 290px);
          }

          &.tabbed-content__tab--entertainment-banking {
            min-width: 150px;

            @include bp-max-768 {
              max-width: 150px;
            }
          }

          &:first-of-type {
              @include flex(0 1 auto);
          }
        }
      }
    }
    .row-of-cards {
      .row-of-cards__card {
        a,
        .row-of-cards__card--not-linked {
          font-family: $font-zh;
          p {
            font-family: $font-zh;
            // font-weight: 700;
          }
        }
      }
    }

    .panel-collapse {
        .panel-body {
            .panel-body__row {
                p {
                    font-family: $font-zh;
                }
            }
        }
    }
  } //end Chinese styles

  &.holiday-ecard {
    background-color: $lightGray;
    @include flexbox;
    @include justify-content(center);
    height: 100vh;
    margin-top: 50px;
  }
}

h1,
span.h1 {
  color: $red;
  @include font-size(1.4);
  font-weight: normal;
  letter-spacing: 0.2em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  @include bp-min-1920 {
    @include font-size(1.8);
  }

  &.size--18px {
    @include font-size(1.8);
  }

  @include bp-max-420 {
    @include font-size(1.4);
  }

  a {
      color: $red;
  }
}

//Any <h1> first child in a <div>
div > h1:first-of-type {
  margin: 0px 0 27px;
}

h2,
span.h2 {
  color: $black;
  display: block;
  @include font-size(4.2);
  font-weight: normal;
  margin: 0;
  padding: 0;

  @include bp-max-768 {
    @include font-size(3);
  }

  @include bp-max-420 {
    @include font-size(2.4);
  }

  &.call-out-box-header {
      @include bp-max-420 {
          @include font-size(3);
          margin-bottom: 30px;
      }
  }

  strong {
    font-family: $font-main-semibold;
    font-weight: normal;
  }

  &.font--medium {
    font-family: $font-main-medium;
  }

  a {
    color: $black;
  }
}

//Any <h2> after an <h1>
h1 + h2 {
  margin-top: 27px;
}

h1.mobile--not-visible + h2 {
  margin-top: 0;
}

//Any <p> after an h2
h2 + p {
  margin-top: 20px;
}

h3 {
  color: $black;
  font-family: $font-main-semibold-italic;
  @include font-size(2.4);
  font-weight: normal;
  margin: 0 0 30px;
  padding: 0;

  & + .btns-row + .btns-row + .btns-row {
    margin-top: 10px;
  }

  @include bp-max-768 {
    @include font-size(2);
    margin-bottom: 20px;

    & + .btns-row {
      margin-top: 10px;
    }

    & + .btns-row + .btns-row {
      margin-top: 10px;
    }
  }

  @include bp-max-420 {
    @include font-size(1.8);
  }
}

h4 {
  @include font-size(2);
  font-family: $font-main-semibold;
  margin: 0;
  text-transform: uppercase;
}

h4 + p {
  line-height: 1.5em;
  margin-top: 10px;
}

p {
  color: $gunmetal;
  @include font-size(1.8);
  font-weight: normal;
  line-height: 1.2em;
  margin: 0;
  padding: 0;

  @include bp-min-1920 {
    @include font-size(2);
  }

  @include bp-max-420 {
    @include font-size(1.6);
  }

  &.intro {
      @include font-size(2.2);

      @include bp-max-768 {
          @include font-size(1.8);
      }
  }
}

//Any <a> after a <p>
p + .btn {
  margin-top: 25px;

  @include bp-min-1920 {
    margin-top: 42px;
  }
}

a {
  text-decoration: none;
}

em {
  font-family: $font-main-semibold-italic;
}

.btn {
  background-color: transparent;
  border-radius: 0px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-main-medium;
  @include font-size(1.8);
  line-height: 1em;
  margin: 0;
  padding: 10px 27px;
  position: relative;
  text-decoration: none;
  width: max-content;
  z-index: 1;
  transition: all 200ms cubic-bezier(0.44, 0.01, 0.88, 0.28);

  @include bp-min-1920 {
    @include font-size(2);
  }

  @include bp-max-420 {
    @include font-size(1.6);
  }
}

.btn-border {
  background-color: $white;
  border: solid 2px $red;
  color: $red;
  transition: all 200ms cubic-bezier(0.44, 0.01, 0.88, 0.28);

  .fill {
    background-color: darken($red, 10%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    z-index: -1;
    transition: all 300ms cubic-bezier(0.6, 0.15, 0.34, 1);
  }

  &:hover {
    color: $white;
    .fill {
      background-color: $red;
      width: 100%;
    }
  }
}

.btn-solid {
    background-color: $red;
    color: $white;

    .fill {
        background-color: darken($white, 10%);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        z-index: -1;
        transition: all 400ms cubic-bezier(0.13, 0.74, 0.34, 1);
    }

    &:hover {
        color: $red;

        .fill {
            background-color: darken($white, 10%);
            width: 100%;
        }

        .icon--white-on-red-bg {
            .icon--white {
                fill: $red;
            }
        }
    }

    &.btn--big {
        display: block;
        @include font-size(4.2);
        font-family: $font-main-book;
        margin: 0 auto;
        max-width: 900px;
        min-width: 580px;
        padding: 40px 80px;
        position: relative;
        top: 0;
        text-align: center;

        @include bp-max-1024 {
            max-width: 100%;
            min-width: inherit;
        }

        @include bp-max-420 {
            @include font-size(3.6);
            margin-bottom: 30px;
            max-width: 90%;
            min-width: 90%;
            padding: 40px 0;
            text-align: center;
            width: 100%;
        }

        &:last-child {
            margin-top: 40px;
            top: 0;

            @include bp-max-420 {
                margin: 0 auto;
                padding: 20px 0px;
            }
        }

        &.btn--big--has-icon {
            @include flexbox;
            @include justify-content(space-around);
            @include align-items(center);
            max-width: 990px;

            @include bp-max-1024 {
                max-width: 100%;
                padding: 40px;
            }

            svg {
                margin-right: 15px;
                width: 30px;

                @include bp-max-768 {
                    width: 25px;
                }

                @include bp-max-420 {
                    width: 30px;
                }
            }

            .btn--big--has-icon__label {
                @include font-size(5.3);

                @include bp-max-1024 {
                    @include font-size(4.5);
                }

                @include bp-max-768 {
                    @include font-size(4);
                }

                @include bp-max-420 {
                    @include font-size(2.5);
                }
            }

            &:last-child {


                @include bp-max-1024 {
                    margin-top: 50px;
                }
            }

            .icon--open-new-window {
                margin: 0 10px;
                width: 50px;

                @include bp-max-420 {
                    display: none;
                }
            }
        }
    }
}

.btns-row {
  margin-right: 10px;
}

.btns-row:last-of-type {
  margin-right: 0;
}

.btn-video-play {
  border-radius: 100%;
  border: 5px solid $red;
  cursor: pointer;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  height: 110px;
  width: 110px;
  z-index: 2;

  @include bp-max-420 {
    height: 70px;
    width: 70px;
  }

  .btn-video-play__triangle {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #ce1b0e;
    margin-left: 10px;

    @include bp-max-420 {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 20px solid #ce1b0e;
      margin-left: 5px;
    }
  }
}

.btn-video-play + p {
  margin-top: 20px;
}

.btn-circ-white-bg {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0px 8px 0px rgba(0, 0, 0, 0.33);
  cursor: pointer;
  @include flexbox;
  height: 40px;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  padding: 10px;
  width: 40px;
  transition: all 300ms;
}

.txt-link {
  border: none;
  color: $gunmetal;
  cursor: pointer;
  font-family: $font-main-medium;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: all 250ms ease-in-out;

  @include bp-min-1920 {
    @include font-size(2);
  }

  &.txt-link--has-arrow {
    @include flexbox;
    @include align-items(center);
  }

  &.txt-link--bold {
    font-family: $font-main-semibold;
  }

  &.txt-link--red {
    color: $red;
  }

  &:hover {
    color: $red;

    i.arrow {
      transform: translateX(5px);
    }
  }

  &.txt-link--active {
    color: $red;
    font-family: $font-main-semibold;
  }
}

strong {
  font-family: $font-main-semibold;
  font-weight: 700;
}

ul {
  color: $gunmetal;
  list-style: none;
  margin: 0;
  padding: 0;
}

header {
  .primary-wrap {
    .primary {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        .txt-link {
          @include font-size(1.8);
          margin-right: 28px;
          padding: 4px 0;

          @include bp-min-1920 {
            @include font-size(2);
          }
          @include bp-max-768 {
            @include font-size(1.6);
          }

          &.mega--is-open {
            color: $red;
          }
        }
        .login-padlock {
          height: 16.28px;
          width: 12px;

          @include bp-max-420 {
            display: none;
          }
        }
        a:hover {
          .login-padlock {
            .icon--red {
              fill: white;
            }
          }
        }
      }
      li:last-of-type .txt-link {
        margin-right: 0;
      }
    }
  }
  .tertiary-wrap {
    .global {
      ul {
        li {
          &:first-of-type {
            a {
              margin-left: 0;
            }
          }
        }
      }
    }
    .tertiary {
      position: relative;
      img {
        position: absolute;
      }
      .txt-link__with-icon {
        margin-left: 18px;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        .txt-link {
          border-right: 1px solid #959595;
          @include font-size(1.4);
          font-family: $font-main-book;
          margin-left: 10px;
          padding-right: 10px;

          @include bp-min-1920 {
            @include font-size(1.6);
          }

          &.txt-link--active {
            font-family: $font-main-semibold;
          }
        }
      }
      li:last-of-type {
        .txt-link {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }
}

footer {
  section {
    h2 {
      @include font-size(1.8);
      font-family: $font-main-semibold;
      line-height: 1.2em;

      @include bp-min-1920 {
        @include font-size(2);
      }

      a.txt-link {
        color: $black;
        font-family: $font-main-medium;
        line-height: 1.2em;
      }
    }
    ul {
      @include font-size(1.4);
      list-style: none;

      @include bp-min-1920 {
        @include font-size(1.4);
      }

      li {
        line-height: 1.5em;

        a.txt-link {
          font-family: $font-main-book;
          @include font-size(1.4);
          line-height: 1.2em;

          @include bp-min-1920 {
            @include font-size(1.4);
          }
        }
      }
    }
  }
  .legal {
    @include font-size(1.4);
    @include flexbox;
    @include justify-content(space-between);

    @include bp-min-1920 {
      @include font-size(1.4);
    }

    ul {
      li {
        border-right: 1px solid $gunmetal;

        .txt-link {
            @include bp-min-1920 {
                @include font-size(1.4);
            }
        }
       }
      li:last-of-type {
        border-right: none;
      }
    }

    p {
      @include font-size(1.4);
      text-align: right;

      @include bp-min-1920 {
        @include font-size(1.4);
      }
    }
  }

  .footer__sticky {
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.3);
    z-index: 9;

    a {
      &.footer__sticky--active {
        background-color: $red;

        svg {
          fill: $white;
        }
      }

      svg {
        fill: $gunmetal;

        .footer__icon--stroke {
          fill: none;
          stroke: $gunmetal;
          stroke-width: 1.2;
        }
      }
    }
  }
}

svg {
  .icon {
    &.icon--red {
      fill: $red;
    }

    &.icon--white {
      fill: $white;
    }
  }
}

.has-gray-bg {
    background-color: $lightGray;

    .btn--big {

        @include bp-max-420 {
            top: 50px;
        }

        &:last-child {
            top: 70px;

            @include bp-max-420 {
                top: 39px;
            }
        }

        &:only-child {

        }
    }
}

.has-quad__carousel {
    &.has-quad__carousel--has-dark-bg {
        h3 {
            color: $white;
        }
    }
}

.art-program__title {
    color: $red;
    display: block;
    font-family: $font-main-medium;
    @include font-size(2);
    margin-bottom: 20px;
    text-transform: uppercase;

    @include bp-max-768 {
        margin-top: 20px;
    }
}

.art-program__details {
    color: $gunmetal;
    font-family: $font-main-medium;
    @include font-size(1.4);
    margin-bottom: 40px;
    text-transform: uppercase;
}

.art-program__artist {
    color: $gunmetal;
    font-family: $font-main-book;
    @include font-size(3);

}
.art-program__artist + p {
    @include font-size(2);
    margin-top: 10px !important;
}

.art-program__bio {
    @include font-size(2);
    height: 160px;
    margin-bottom: 20px;
    overflow: hidden;
    transition: height 500ms;
}

#footer-email {
    a {
        color: $red;
    }
}