﻿/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

.tabbed-content {
    .tabbed-content__content {
        .not-full-width {
            &.not-full-with--has-table-wrapper {
                @include bp-max-420 {
                    margin: 0;
                    padding: 0 10px;
                }
            }
        }
    }
}

.table {
    border-collapse: collapse;
    margin: 20px 0px;
    width: 100%;

    .table__header-row {
        padding: 10px;
        color: #ffffff;
        border: 1px solid #000000;
        background-color: #767676;
    }

    th {
        color: #ffffff;
        background-color: #767676;
    }

    td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
    }

    &.table-bordered {
        border: 1px solid #ddd;
        border-spacing: 0;

        tr {
            border: 1px solid #000000;

            &:nth-child(odd) {
                background-color: $lightGray;
            }

            &.table__header-row {
                background-color: #767676;

                @include bp-max-768 {
                    @include font-size(1.4);
                }

                @include bp-max-420 {
                    @include font-size(1);
                }
            }

            td {
                border: 1px solid #ddd;

                @include bp-max-768 {
                    @include font-size(1.4);
                }

                @include bp-max-420 {
                    @include font-size(1);
                    padding: 5px;
                }
            }
        }
    }
}
