/// <reference path="../_variables.scss" />
/// <reference path="../_mixins.scss" />

.items {
    padding: 0 30px;

    &.carousel--with-divider {
        .item {
            border-right: 1px solid $gray;
            padding-right: 50px;

            &:last-of-type {
                border-right: none;
            }
        }
    }

    &.carousel--has-dark-bg {
        .flickity-button {
            color: $white;
        }

        p {
            color: $white;
            font-family: $font-main-semibold;
            text-transform: uppercase;
        }
    }

    &.items--with-border {
        &.items--with-border-white-4px {
            .item {
                img {
                    border: 4px solid white;
                }
            }
        }
    }

    .item {
        margin-right: 50px;
        width: 150px;

        @include bp-max-1024 {
            margin-right: 20px;
        }

        .item--with-border {
            border: 1px solid $gray;
        }

        > img {
            max-width: 100%;
        }

        &.item--is-image-only {
            text-align: center;
            width: 25%;

            @include bp-max-768 {
                width: 33%;
            }

            @include bp-max-420 {
                width: 100%;
            }

            > img {
                height: auto;
                max-width: 100%;
            }
        }

        &.item--with-label {
            width: 25%;
        }

        > img + p {
            @include font-size(1.4);
            text-align: center;
        }
    }

    .flickity-button {
        background: transparent;
        color: $red;

        &.flickity-prev-next-button.previous {
            left: -10px;
        }

        &.flickity-prev-next-button.next {
            right: -10px;
        }

        .flickity-button-icon {
            transition: transform 300ms ease-in-out;
        }

        &:hover {
            background: transparent;
            color: darken($red, 10%);

            &.flickity-prev-next-button.previous {
                .flickity-button-icon {
                    transform: translateX(-5px);
                }
            }

            &.flickity-prev-next-button.next {
                .flickity-button-icon {
                    transform: translateX(5px);
                }
            }
        }
    }

    .flickity-page-dots {
        bottom: -25px;
    }
}

.news {
  .flickity-button {
    height: 81px;
    width: 81px;

    &.flickity-prev-next-button.previous {
      left: -75px;

      @include bp-max-420 {
        left: -40px;
      }
    }

    &.flickity-prev-next-button.next {
      right: -75px;

      @include bp-max-420 {
        right: -40px;
      }
    }
  }
  .flickity-page-dots {
    bottom: -50px;
  }
}
